import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import {addBreadCrumbs, clearBreadCrumbs} from "../../js/actions";
import {connect} from "react-redux";
import CustomizedSnackbars from "../../Components/CustomizedSnackbars";
import config from "../../config/config";



class PrivateRouter extends Component {
    constructor(props){
        super(props);
        this.updateBreadCrumbs();
        this.state={
            redirect: false,
            path:''
        }
    }

    getRoute = (path, query='') => `${path}?app=${this.props.app}${!query ? '' : `&${query}`}`;

    isAuthenticated = () => {
        return localStorage.getItem("token_patient")
    };

    isAuthenticatedOrForgot = () => {
        const { location } = this.props;
        if(config[this.props.app].noAuth) {
            localStorage.setItem("token_patient", "-1")
            localStorage.setItem("token_system", "-1")
            localStorage.setItem("system", config[this.props.app].systemUser)
        }
        return localStorage.getItem("token_patient") || config[this.props.app].noAuth ||location.pathname === '/forgot-password' || location.pathname.split('-')[0] === '/open'
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log('bread:',this.props.breadCrumbs, this.props.breadCrumbs[this.props.breadCrumbs.length -1])
        if((prevProps.breadCrumbs.length > this.props.breadCrumbs.length) && this.isAuthenticated()){
            this.setState({
                // redirect:true,
                path: !this.props.breadCrumbs[this.props.breadCrumbs.length -1] ? '/menu' : this.props.breadCrumbs[this.props.breadCrumbs.length -1]
            },  () => this.setState({redirect:false}))
        } else {
            if(this.props.location.pathname !== prevProps.location.pathname){
                this.updateBreadCrumbs();
            }
        }
    }

    updateBreadCrumbs = () => {
        const { location } = this.props;

        if(location.pathname === '/menu' || location.pathname === '/menu/' || location.pathname === '/') {
            this.props.clearBreadCrumbs();
        } else {
            this.props.addBreadCrumbs(location.pathname);
        }
    };

    render() {

        return (
            <>
                {!this.isAuthenticatedOrForgot() && <Redirect to={this.getRoute('/login')}/>}
                {this.state.redirect && <Redirect to={this.getRoute(this.state.path)}/>}
                {this.props.children}
                <CustomizedSnackbars/>
            </>
        )
    }
}

const mapStateToProps = (state) => ({ breadCrumbs: state.breadCrumbs});
const mapDispatchToProps = (dispatch) => (
    {
        addBreadCrumbs: breadCrumb => dispatch(addBreadCrumbs(breadCrumb)),
        clearBreadCrumbs: () => dispatch(clearBreadCrumbs())
    });
export default connect(mapStateToProps,mapDispatchToProps)(PrivateRouter);



