import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import './style.css';

class Message extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  };
  render() {
    const {message} = this.props;

    return (
      <div className={'chat-div'}>
        <Paper className={ message.sender == localStorage.getItem('id_patient') ? 'my-message' : 'your-message'  }>
          <Typography component="p">
            {message.text}
          </Typography>
        </Paper>
        <Typography className={message.sender == localStorage.getItem('id_patient') ? 'time-date-my-message' : 'time-date'}>{message.date} {message.time}</Typography>
      </div>
    )
  }
}

export default Message;
