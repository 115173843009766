import React from 'react';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


export default (props) => {
    return(
        <Paper>
            <Table>
                <TableHead>
                    <TableRow>
                        {props.elements[0].valores_fila.map(rowValue =>
                            (<TableCell>{rowValue.left_item_text}</TableCell>)
                        )}

                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        props.elements.map((row, index)=> (
                            <TableRow key={index}>
                                { row.valores_fila.map((rowValue) => (
                                    <TableCell component="th" scope="row">{rowValue.value}</TableCell>
                                ))}
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </Paper>
    )
}
