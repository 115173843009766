import {Fab, withStyles} from "@material-ui/core";
import { Icon } from 'react-icons-kit'
import * as Md from 'react-icons-kit/md/'
import { Button } from '@material-ui/core';
import React from "react";
import {Link} from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import './style.css';



class ButtonsRectangular extends React.Component {

  getRoute = (path, query='') => `${path}?app=${this.props.app}${!query ? '' : `&${query}`}`;

  getStyle = () => {
      const {color, backgroundColor } = this.props;
      return {backgroundColor: backgroundColor ? backgroundColor : 'transparent', color: color ? color : 'grey'}
  };

  render() {
    const { text, color, icon, data, href, onClick, colorIcon} = this.props;


    return (
      <>
        <ListItem button color={color} onClick={onClick} data={data} component={Link} to={href}>
            <ListItemAvatar>
                <Avatar className={'withBorder'} style={this.getStyle()}>
                    <Icon icon={Md[icon]} style={{color: colorIcon}}/>
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={text}/>
        </ListItem>
        <Divider component="li" variant="inset"/>

      </>
    );
  }
}

export default ButtonsRectangular;
