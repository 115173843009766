// src/js/reducers/index.js
import {
    ADD_ARTICLE,
    LOAD_FORM,
    OPEN_MODAL,
    LOAD_SECTION,
    DELETE_BREAD_CRUMBS,
    ADD_BREAD_CRUMBS,
    CLEAR_BREAD_CRUMBS,
    LOAD_FORM_ID,
    LOAD_MAIN_MENU,
    CHANGE_NAME, SET_AGREEMENT
} from "../constants/action-types";
const initialState = {
    articles: [],
    form: [],
    section: {},
    formId:'',
    breadCrumbs:[],
    mainMenu:[],
    modal:false,
    chatName: null,
    agreement: ""
};
function rootReducer(state = initialState, action) {

    switch(action.type) {
        case  ADD_ARTICLE:
            return Object.assign({}, state, {
                articles: state.articles.concat(action.payload)
            });
        case LOAD_FORM:
            return Object.assign({}, state, {
            form: action.payload
        });
        case OPEN_MODAL:
            console.log("action change:", action.payload)
            return Object.assign({}, state, {
                modal: action.payload
            });
        case CHANGE_NAME:
            console.log("action name:", action.payload)
            return Object.assign({}, state, {
                chatName: action.payload
        });
        case LOAD_MAIN_MENU:
            return Object.assign({}, state, {
                mainMenu: action.payload
            });
        case LOAD_FORM_ID:
            return Object.assign({}, state, {
                formId: action.payload
            });
        case LOAD_SECTION:
            return Object.assign({}, state, {
            section: action.payload
        });
        case ADD_BREAD_CRUMBS:
            if(state.breadCrumbs[state.breadCrumbs.length-1] == action.payload){
                return Object.assign({}, state,{
                    breadCrumbs: [state.breadCrumbs]
                })
            }
            else {
                return Object.assign({}, state, {
                    breadCrumbs: [...state.breadCrumbs, action.payload]
                })
            };
        case DELETE_BREAD_CRUMBS:
            let auxBreadCrumbs = [... state.breadCrumbs];
            auxBreadCrumbs.splice(auxBreadCrumbs.length - 1, 1);
            return Object.assign({}, state,{
               breadCrumbs: auxBreadCrumbs
            });
        case CLEAR_BREAD_CRUMBS:
            return Object.assign({}, state, {breadCrumbs:[]});

        case SET_AGREEMENT:
            return Object.assign({}, state, {
                agreement: action.payload
            });
        default:
            return state;

    }
    return state;
}
export default rootReducer;