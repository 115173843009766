import React from 'react';

import Message from "../../Components/Message";
import './style.css';
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import SendIcon from '@material-ui/icons/Send';
import axios from "axios";
import config from "../../config/config";
import {bodyGet} from "../../utils/utils";
import {connect} from "react-redux";
import {changeName} from "../../js/actions";

class Chat extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      value: "",
      messageEnd: null,
      inputMessage: null,
      messages: [{text: 'texto uno', owner: 0}, {text: 'texto 2', owner: 1},{text: 'texto uno', owner: 0}, {text: 'texto 2', owner: 1},{text: 'texto uno', owner: 0}, {text: 'texto 2', owner: 1},{text: 'texto uno', owner: 0}, {text: 'texto 2', owner: 1},{text: 'texto uno', owner: 0}, {text: 'texto 2', owner: 1},{text: 'texto uno', owner: 0}, {text: 'texto 2', owner: 1},{text: 'texto uno', owner: 0}, {text: 'texto 2', owner: 1},{text: 'texto uno', owner: 0}, {text: 'texto 2', owner: 1},{text: 'texto uno', owner: 0}, {text: 'texto 2', owner: 1},{text: 'texto uno', owner: 0}, {text: 'texto 2', owner: 1},{text: 'texto uno', owner: 0}, {text: 'texto 2', owner: 1},{text: 'texto uno', owner: 0}, {text: 'texto 2', owner: 1},{text: 'texto uno', owner: 0}, {text: 'textotex dasda da dasd asdasd adsa 2', owner: 1}]
    }
    this.handleChange = this.handleChange.bind(this);
  };
  componentDidMount() {
    this.scrollToBottom();
    this.getMessages();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    this.scrollToBottom();
  }
  
  getRoute = (path, query='') => `${path}?app=${this.props.app}${!query ? '' : `&${query}`}`;

  handleChange = (event) => {
    this.setState({value: event.target.value})
  }
  handleClickSend = () =>{
    document.getElementById("input").focus()
    let newMessages = this.state.messages;
    const newMessage = {text: this.state.value, owner: 1, sender: localStorage.getItem('id_patient'), recipient: this.props.userId}
    this.sendMessage(newMessage);
    newMessages.push(newMessage);
    this.setState({messages: newMessages, value: ""})
  }
  sendMessage(message){
    return axios.post(config.apiUrl, bodyGet("OPERACION_SEND_MESSAGE", {message: message}))
      .then(res => {
        console.log(res);
        return res;
      })
      .catch(err => {
        console.log('Error:', err);
        localStorage.removeItem('token_patient');
        this.props.history.push(this.getRoute('/login'));
        return null;
      })
  }
  getMessages(){
    return axios.post(config.apiUrl, bodyGet("OPERACION_GET_MESSAGE", {chatUserId: this.props.userId}))
      .then(res => {
        console.log(res);
        this.setState({messages: res.data.messages}) ;
        console.log(res.data.messages)
        this.props.changeName(res.data.nombre_completo);
        return res;
      })
      .catch(err => {
        console.log('Error:', err);
        localStorage.removeItem('token_patient');
        this.props.history.push(this.getRoute('/login'));
        return null;
      })
  }
  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  }
  scrollToBottomDelay = () => {
    setTimeout(function() { //Start the timer
      this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }.bind(this), 200)
  }
  render() {
    return (
      <>
        <div id={"chatList"}>
          {
            this.state.messages.map((message) =>
              <Message message={message}/>
            )
          }
        </div>
        <div style={{ float:"left", clear: "both" }}
             ref={(el) => { this.messagesEnd = el; }}>
        </div>
        <div className="chatInputWrapper">

          <Paper>
            <InputBase className={"textArea"}
              value={this.state.value}
              onClick={this.scrollToBottomDelay}
              onFocus={this.scrollToBottomDelay}
              onChange={this.handleChange}
              placeholder="Escribe un mensaje"
              id={'input'}
            />
            <IconButton onClick={this.handleClickSend} className={"sendButton"} aria-label="sendMessage">
              <SendIcon />
            </IconButton>
          </Paper>
        </div>
      </>
    );
  }
}


function mapStateToProps(state, props) {
  return {
    userId: props.match.params.id
  };
}
function mapDispatchToProps(dispatch) {
  return {
    changeName: chatUser => dispatch(changeName(chatUser))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
