import React from 'react';
import TextField from '@material-ui/core/TextField';
import './style.css';
import FormLabel from "@material-ui/core/FormLabel";

const getDate = () => new Date().toISOString().split('T')[0];

export default (props) =>  {
  const { id, label, className, onChange } = props;
  let defaultValue = !props.value ? getDate() : props.value;

  return (
    <div className={"divQuestion"}>
      <b>{label}</b>

      <TextField
        id={id}
        type="time"
        onChange={onChange}
        defaultValue={defaultValue}
        fullWidth={true}
        inputProps={{
          step: 300, // 5 min
        }}
      />
    </div>
  );
}
