import React from 'react';
import CustomButton from "../../Components/CustomButton";
import ButtonsRectangular from "../../Components/ButtonsRectangular";
import store from "../../js/store";
import { connect } from "react-redux";
import axios from "axios";
import config from "../../config/config";
import utils, {bodyGet} from "../../utils/utils";
import {loadForm, loadFormId, loadSection, clearBreadCrumbs, openModal, setAgreement} from "../../js/actions";
import Button from "@material-ui/core/Button";
import {Link, Redirect} from "react-router-dom";
import List from "@material-ui/core/List";
import AlertDialogSlide from "../../Components/AlertDialogSlide";
import * as qs from "qs";


class MenuSection extends React.Component {
  constructor(props) {
    super(props);


    this.state = { sections: [], openDialog: false, showButton: false, openInitialDialog: false};
    this.getForm()
        .then(() => this.isOnlyOneForm())
    window.scrollTo(0,0);

  }

  componentDidUpdate(prevProps) {
    // Uso tipico (no olvides de comparar los props):
    if (this.props.sections !== prevProps.sections) {
      this.setState({sections: this.props.sections})
    }
  }

  componentDidMount() {
      this.props.loadFormId(this.props.formId)
    if(this.getJsonSearch().opentoken){
      this.setState({openInitialDialog: true})
    }
  }

  getJsonSearch(location = window.location){
    return qs.parse(location.search.substring(1));
  }

  isOnlyOneForm(){
      console.log('Tiene un form',this.state.sections.length === 1)
      if(this.state.sections.length === 1){
          this.handleClick(this.state.sections[0]);
          this.props.clearBreadCrumbs();

          if(window.location.pathname === "/open-sections") {
            this.setState({openInitialDialog: true})
            this.props.history.push(this.getRoute('/open-element', window.location.search));
          }
          else
            this.props.history.push(this.getRoute('/element'));
      }

  }

  getForm(){
    console.log('Wow', this.getJsonSearch().opentoken)
    return axios.post(config.apiUrl, bodyGet("OPERACION_6_MOV", {form: this.props.formId, opentoken: this.getJsonSearch().opentoken, userSign: utils.getUserSign()}))
        .then(async res => {
            console.log('-------', res.data.ci);
            if (!!res.data.formulario) {
                await this.props.loadForm(res.data.formulario)
                if(this.state.sections.filter(o => o.completado).length == this.state.sections.length){
                    console.log('Entra');
                    this.setState({openDialog: true, showButton: true});
                }
            }
            if(res.data.ci)
              this.props.setAgreement(res.data.ci)
            return res;
        })
        .catch(err => {
          console.log('Error:', err);
            localStorage.removeItem('token_patient');
            this.props.history.push(this.getRoute('/login'));
            return null;
        })
  }

  getRoute = (path, query) => {
    return `${path}?app=${this.props.app}${!query ? '' : `&${query.substring(1)}`}`;
  }
  handleClick = (section) =>{
    console.log("Entra en el handle")
    this.props.loadSection(section)
  }

  handleClose = () => this.setState({openDialog: false});

  handleInitialClose = () => {
    localStorage.setItem("first-time", "false");
    this.setState({openInitialDialog: false})
  };

  openSuccessModal = () => this.props.openModal(true);

    render() {

    return (
      <>
          <div>
            <List component="nav"  aria-label="mailbox folders">
            {
              this.state.sections.map((section, index) =>
                <ButtonsRectangular
                  key={`section-${index}`}
                  id={index}
                  history={this.props.history}
                  text={section.nombre_seccion}
                  color={section.completado ? "#4caf50" : "#d00f3b"}
                  icon={section.completado ? "ic_done" : "ic_clear"}
                  data={section}
                  onClick={() => this.handleClick(section)}
                  href={window.location.pathname === "/open-sections" ? this.getRoute('/open-element', window.location.search) : this.getRoute('/element')}
                />
              )
            }


            </List>
              {this.state.showButton ?
                  <Button variant="contained" style={{backgroundColor:  config[this.props.app].color}} color="primary" onClick={this.openSuccessModal} component={Link} to={window.location.pathname === "/open-sections" ? this.getRoute('/open-end', window.location.search) : this.getRoute('/recommendation')}>
                    Terminar Formulario
                  </Button> : ""}
          </div>

        {/*Dialog que nos muestra si necesitas pulsar el botón finalizar*/}
          <AlertDialogSlide
              title={'Formulario Completado'}
              contentText={"Si no desea modificar el formulario, por favor, pulse en el botón TERMINAR"}
              open={this.state.openDialog}
              handleClose={this.handleClose}
          />
        {/*Dialog que muestra la pantalla de inicio con varios formularios*/}
          <AlertDialogSlide
              title={'Formulario Virtual'}
              contentText={"Bienvenido a la aplicación de cuestionarios de salud. Podrá completar el formulario hospitalario de forma rápida y sencilla"}
              open={this.state.openInitialDialog && localStorage.getItem("first-time") != "false"}
              handleClose={this.handleInitialClose}
              image={"https://img.freepik.com/vector-gratis/grupo-personal-medico-que-lleva-iconos-relacionados-salud_53876-43071.jpg"}
          />
      </>
    );
  }
}

function mapStateToProps(state, props) {
  return {
      sections: state.form,
      // formId: state.formId,
      formId: props.match.params.id
  };
}
function mapDispatchToProps(dispatch) {
  return {
    loadSection: section => dispatch(loadSection(section)),
    loadForm: form => dispatch(loadForm(form)),
    loadFormId: formId => dispatch(loadFormId(formId)),
    clearBreadCrumbs: () => dispatch(clearBreadCrumbs()),
    setAgreement: agreement => dispatch(setAgreement(agreement)),
    openModal: open => dispatch(openModal(open))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuSection);
