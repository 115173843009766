import React from 'react';
import InfiniteCalendar, {withMultipleDates} from 'react-infinite-calendar';
import 'react-infinite-calendar/styles.css';
import {Calendar as CalendarLib} from 'react-infinite-calendar';
import AlertDialogSlide from "../../Components/AlertDialogSlide";
import axios from "axios";
import config from "../../config/config";
import {bodyGet} from "../../utils/utils";
import ButtonsRectangular from "../MenuSections";

class Calendar extends React.Component {

    constructor(props) {
        super(props);
        this.getCalendarDates();
        this.state = {
            selectedDate: [],
            openDialog: false,
            appointments: [],
            title: '',
            contentText: ''
        };
        window.scrollTo(0,0);

    }


    createArraySelectedDate = (appointments) => {
        // Se crea el array de días existentes y se ordena por fecha
        let auxDates = [];
        appointments.map((appointment) => {
            auxDates.push(new Date(appointment.fecha));
        })
        // auxDates = auxDates.sort();
        console.log(auxDates);

        const cotainsToday = auxDates.findIndex( o => this.compareDates(o, new Date()));
        if(cotainsToday !== -1){
            console.log('entra mal')
        }
        else {
            auxDates.splice(0,0, new Date ());
        }
        return auxDates;
    }

    getCalendarDates (){
        return axios.post(config.apiUrl, bodyGet("OPERACION_CALENDAR"))
            .then(res => {
                console.log('-------',res);
                this.setState({appointments: res.data.calendario, selectedDate: this.createArraySelectedDate(res.data.calendario)});
            })
            .catch(err => {
                console.log('Error:', err);
                return null;
            })
    }

    compareDates = (d1,d2) => d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear();


    handleClose = () => this.setState({openDialog: false});

    handleSelection = (date, selected) => {
        const foundDateIndex = selected.findIndex( o => this.compareDates(o, date));
        if(foundDateIndex != -1){
            //Cambio el orden del array Selected para que se mantenga en pantalla la fecha seleccionada
            selected.splice(foundDateIndex,1);
            selected.splice(0,0, new Date (date));

            const foundAppointment = this.state.appointments.find( o => this.compareDates(new Date (o.fecha), date));
            const message = !foundAppointment ? "No existe evento" : foundAppointment.comentarios
            const subject = !foundAppointment ? "Hoy" : foundAppointment.asunto
            this.setState({openDialog: true, contentText: message, title: subject })
        }
        return selected;
    }

    onSelect = () => this.setState({openDialog: true});

    getRoute = (path, query='') => `${path}?app=${this.props.app}${!query ? '' : `&${query}`}`;

    render() {
        const app = this.props.app;

        return (
            <>
                <InfiniteCalendar
                    Component={withMultipleDates(CalendarLib)}
                    width={(window.innerWidth <= 650) ? window.innerWidth : 650}
                    height={window.innerHeight - 100}
                    locale={{
                        locale: require('date-fns/locale/es'),
                        headerFormat: 'D MMM.',
                        weekStartsOn: 1,
                        weekdays: ["D","L","M","X","J","V","S"],
                        todayLabel: {
                            long: 'Hoy',
                            short: 'Hoy'
                        }
                    }}


                    theme={{
                        selectionColor: date => {
                            return this.compareDates(new Date (date), new Date())  ? config[app].colorTertiary : config[app].color;
                        },
                        textColor: {
                            default: '#333',
                            active: '#FFF'
                        },
                        weekdayColor: config[app].colorSecondary,
                        headerColor: config[app].color,
                        floatingNav: {
                        background: config[app].colorTertiary,
                        color: '#FFF',
                        chevron: '#FFA726'
                    }
                    }}
                    interpolateSelection={this.handleSelection}
                    autoFocus={false}
                    selected={this.state.selectedDate}
                />
                <AlertDialogSlide
                    title={this.state.title}
                    contentText={this.state.contentText}
                    open={this.state.openDialog}
                    handleClose={this.handleClose}
                />
            </>
        );
    }
}

export default Calendar;