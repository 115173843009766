import React from 'react'
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import ReactDOM from "react-dom";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class SelectSimple extends React.Component {
    constructor(props){
        super(props);
        this.InputLabelRef = null;
        this.state={
            labelWidth:0,
            options: [],
        }
    }

    componentDidMount() {
        this.setState({
            labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
            options: this.parseOptions()
        });
    }

    parseOptions=()=>{
        console.log(this.props.options);
        let newOptions = this.props.options.split(/\[|]|,/);
        return newOptions.slice(1,newOptions.length-1);
    };

    handleChange = (e) => {
        this.props.onChange(e)
    };

    render(){
        const { name, label, value} = this.props;
        const {options, labelWidth} = this.state;

        let {classes} = this.props;

        if(!classes)
            classes={};

        return (
          <div className={"divQuestion"}>
              <b>{label}</b>
                <Select
                    multiple
                    value={[]}
                    renderValue={selected => selected.join(', ')}
                    onChange={this.handleChange}
                    input={
                        <OutlinedInput
                            labelWidth={labelWidth}
                            name={name}
                            id={`outlined-age-simple-${name}`}
                        />
                    }
                    MenuProps={MenuProps}
                >
                    {console.log(options)}
                    {options.map(option =>(
                        <MenuItem value={option}>
                            <Checkbox checked={value ? value.indexOf(option) > -1 : false} />
                            <ListItemText primary= {option} />
                        </MenuItem>
                    ))}

                </Select>
            </div>
        )
    }
}

export default SelectSimple;