import React from 'react';
import TextField from '@material-ui/core/TextField';
import {withStyles} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import './style.css';

const styles = theme => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
});

export default withStyles(styles)(({label, ...props}) => {
    console.log(props)
    return (

      <div className={"divQuestion"}>
        <b>{label}</b>
            <TextField
                type='file'
                autoComplete="off"
                autoFocus
                onChange={props.onChange}
                InputLabelProps={{shrink: !!props.value || props.focus}}
                margin="normal"
            />
        </div>
    )

});
