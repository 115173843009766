import React from 'react';
import TextField from '@material-ui/core/TextField';
import './style.css';
import FormLabel from "@material-ui/core/FormLabel";

const getDate = () => new Date().toISOString().split('T')[0];

export default (props) =>  {
  const { id, label, className, onChange } = props;
  let defaultValue = !props.value ? getDate() : props.value;

  return (
    <div className={"divQuestion"}>
      <b>{label}</b>
      <div style={{marginTop: "24px"}}>
      <TextField
        id={id}
        type="date"
        onChange={onChange}
        defaultValue={defaultValue}
        fullWidth={true}
        InputLabelProps={{
          shrink: true,
        }}
      />
      </div>

    </div>
  );
}
