import * as qs from "qs";
import { v4 as uuidv4 } from 'uuid';

const bodyGet = (type, extra={}) => {
    return Object.assign(extra, {
        tipo: type,
        usuario: localStorage.getItem("system"),
        token: localStorage.getItem("token_system"),
        nombre_paciente: localStorage.getItem("patient"),
        token_paciente: localStorage.getItem("token_patient"),
        id_usuario_sistema: localStorage.getItem("id"),
        id_estudio: localStorage.getItem("id_essay"),
        id_centro: localStorage.getItem("id_center"),
        paciente: localStorage.getItem("id_patient")
    })
}

export {
    bodyGet
};

class Utils {

    static getRedirectWithApp(pathname, location = window.location) {
        const search = this.getJsonSearch();
        return {
            pathname: pathname,
            search: location.search,
        }
    }

    static getJsonSearch(location = window.location){
        return qs.parse(location.search.substring(1));
    }

    static getBedId(url){
        const last = url.split("?")
        return qs.parse(last[1]).bedId
    }

    static getUserSign(){

        // Intentar obtener el userId del localStorage
        let id = localStorage.getItem('userSign');

        // Si no existe un userId, crear uno nuevo y guardarlo en el localStorage
        if (!id) {
            id = uuidv4();
            localStorage.setItem('userSign', id);
        }
        // Establecer el userId en el estado del componente
        return id;
    }

    static getApp = () => {
        if(window.location.search === '') {
            return 'default';
        } else {
            const app = window.location.search.split('app=')[1];
            return !app ? 'default' : app.split('&')[0]
        }
    };
}

export default Utils;