import * as firebase from "firebase"

export const initializeFirebase = () => {
    const config = {
        apiKey: "AIzaSyBjYwB23ZQf6bI-HKqWv_7CZPVF2HH2pbk",
        authDomain: "cronicare-a5f47.firebaseapp.com",
        databaseURL: "https://cronicare-a5f47.firebaseio.com",
        projectId: "cronicare-a5f47",
        storageBucket: "cronicare-a5f47.appspot.com",
        messagingSenderId: "154866238659",
        appId: "1:154866238659:web:72dce8699b0144ee3ece76",
        measurementId: "G-GR641XN662"
    };
    firebase.initializeApp(config);
    firebase.analytics();
}

export const askForPermissionToReceiveNotifications = async () => {
    try {
        console.log('askpermission')
        const messaging = firebase.messaging();
        await Notification.requestPermission();
        const token = await messaging.getToken();
        console.log('token:', token);
        localStorage.setItem("notification-token", token);

        return token
    } catch (error) {
        console.log(error);
    }
}