import store from "../js/store/index";
import { addArticle, loadForm, loadSection, addBreadCrumbs, clearBreadCrumbs, deleteBreadCrumbs, openModal, setAgreement } from "../js/actions/index";
window.store = store;
window.addArticle = addArticle;
window.loadForm = loadForm;
window.openModal = openModal;
window.loadSection = loadSection;
window.addBreadCrumbs = addBreadCrumbs;
window.clearBreadCrumbs = clearBreadCrumbs;
window.deleteBreadCrumbs = deleteBreadCrumbs;
window.setAgreement = setAgreement;