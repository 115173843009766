import React from 'react';
import {FormControl, FormLabel, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import './style.css';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing.unit * 3,
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
  },
  texAlignLeft: {
      'text-align': 'left'
  }
});

export default withStyles(styles)(({...props}) => (
  <div className={"divQuestion"}>
    <b>{props.label}</b>
    <RadioGroup
      className={props.classes.group}
      {...props}
    >
      {props.options.map((option,index) =>
        <FormControlLabel className={props.classes.texAlignLeft}
          key={`option-${index}`}
          value={option.db_value}
          control={<Radio />}
          label={option.display_value}
        />
      )}
    </RadioGroup>
  </div>
))
