import React from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import * as Md from 'react-icons-kit/md/'
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import {IconButton, Toolbar} from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Icon} from "react-icons-kit";
import config from "../../config/config";

const styles = {
    list: {
        width: 250,
    },
    itemIcon: {
        'min-width':'45px'
    }
};

class TemporaryDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            left: false,
            mainMenu: !this.props.mainMenu ? []:this.props.mainMenu
        };
    }

    getRoute = (path, query='') => `${path}?app=${this.props.app}${!query ? '' : `&${query}`}`;

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.mainMenu != this.props.mainMenu)
            this.setState({ ...this.state, mainMenu: !this.props.mainMenu ? [] : this.props.mainMenu});
    }

    closeSession = () => {
        localStorage.removeItem('token_patient');
        this.props.history.push(this.getRoute('/login'));
    }

    toggleDrawer = (open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        this.setState({ ...this.state, left: open });
    };

    sideList = () => (

        <div
            className={this.props.classes.list}
            role="presentation"

            onClick={this.toggleDrawer( false)}
            onKeyDown={this.toggleDrawer( false)}
        >
            {/*<Divider/>*/}
            {/*<List>*/}
                {/*{['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (*/}
                    {/*<ListItem button key={text}>*/}
                        {/*<ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>*/}
                        {/*<ListItemText primary={text} />*/}
                    {/*</ListItem>*/}
                {/*))}*/}
            {/*</List>*/}
            {/*<Divider />*/}
            <div style={{'min-height': 62, background: config[this.props.app].color, color: config[this.props.app].fontcolor}}>
                <List>

                    {this.state.mainMenu.map((element, index) => (
                        index == 0 ?
                        <ListItem button key={element.title} component={Link} to={this.getRoute(element.redirect)}>
                            <ListItemIcon className={this.props.classes.itemIcon} style={{color: config[this.props.app].fontcolor}}><Icon size={27} icon={Md[element.icon]}/></ListItemIcon>
                            <ListItemText primary={element.title} />
                        </ListItem>:""
                    ))}

                </List>
            </div>
            <List>
                {this.state.mainMenu.map((element, index) => (
                    index != 0 ?
                        <ListItem button key={element.title} component={Link} to={this.getRoute(element.redirect)}>
                        <ListItemIcon className={this.props.classes.itemIcon}><Icon size={27} icon={Md[element.icon]}/></ListItemIcon>
                        <ListItemText primary={element.title} />
                        </ListItem>:""
                ))}
                <ListItem button onClick={this.closeSession}>
                    <ListItemIcon className={this.props.classes.itemIcon}><Icon size={27} icon={Md['ic_exit_to_app']}/></ListItemIcon>
                    <ListItemText primary={'Cerrar sesión'} />
                </ListItem>
            </List>
        </div>
    );

    render() {

        return (
            <div>
                <IconButton color="inherit" onClick={this.toggleDrawer(true)}>
                    <MenuIcon/>
                </IconButton>
                <Drawer open={this.state.left} onClose={this.toggleDrawer(false)}>
                    {this.sideList('left')}
                </Drawer>
            </div>
        );
    }
}
function mapStateToProps(state, props) {
    return {
        mainMenu: state.mainMenu
    };
}
export default connect(mapStateToProps)(withStyles(styles)(TemporaryDrawer));