import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Button,  withStyles } from '@material-ui/core';
import Field from '../Field';
import config from "../../config/config";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import * as Md from 'react-icons-kit/md/'
import {Icon} from "react-icons-kit";
import IconAppBar from "../IconAppBar";
import useDetectKeyboardOpen from "use-detect-keyboard-open";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
  },
}));
const styles = theme => ({
  container: {
    display: 'flex',
    flex:1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    paddingTop: '66px',
  },
  textField: {

    margin: '20px'
    // marginLeft: theme.spacing.unit,
    // marginRight: theme.spacing.unit,
    // width: 200,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  button: {
    margin: '20px',
    width: "100%",
    borderRadius: "24px",
  },
  footer: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    paddingLeft: "0px",
    boxShadow: "0 0 0.625rem -0.125rem #d6dee6",
    backgroundColor: "#fff",
  },
  footerButtons: {
    display: "flex",
  },
  header: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
  },
  headerContainer: {
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: "8px",
    paddingTop: "8px",
    display: "flex"
  },
});


const BasicForm = ({...props}) => {
  const isKeyboardOpen = useDetectKeyboardOpen();
  const [footerOffset, setFooterOffset] = useState(0);

  useEffect(() => {
    if (isKeyboardOpen) {
      setTimeout(() => {
        // Calcula el desplazamiento necesario
        const offset = window.innerHeight - window.visualViewport.height;
        setFooterOffset(offset);
      }, 100); // Un retraso de 100ms
    } else {
      setFooterOffset(0);
    }
  }, [isKeyboardOpen]);

  const parseField = (o) => ({
    id: o.item_name,
    label: o.left_item_text,
    type: o.field_type,
    dataType: o.data_type,
    value: o.value,
    // defaultValue: o.default_value,
    options: o.response_options,
    onChange: o.onChange,
    elements: o.elementos,
    required: o.required,
    calculation: o.calculation,
    max: o.validation_max,
    min: o.validation_min
  });

  const isValidate = () => !(props.required && props.value.length === 0);

    const { classes, isLastElement, isFirstElement, completed, field_type } = props;
    console.log('test', props);
    const field = parseField({...props});
    return (
      <>
        <header className={classes.header}>
          <div className={classes.headerContainer}>
            <div style={{margin: 4}} >
              <Button className={"buttonIcon"}>
                <Icon icon={Md["ic_help_outline"]} />
              </Button>
            </div>
            <div style={{width: "100%"}}>
              <BorderLinearProgress color={"primary"} variant="determinate" value={completed}/>
            </div>
            <div style={{margin: 4}}>
              <IconAppBar close={true} {...props}/>
              {/*<Button  className={"buttonIcon"}>*/}
              {/*  <Icon  icon={Md["ic_close"]} />*/}
              {/*</Button>*/}
            </div>
          </div>
        </header>

        <div className={classes.container} >
          <Field className={classes.textField} variant="outlined" next={props.onClick} {...field} />
        </div>
        <div className={classes.footer} style={{bottom: footerOffset}}>
          <div className={classes.footerButtons}>
            <Button style={{backgroundColor:  !isFirstElement ? config[props.app].color : ''}} variant="contained" color="primary" className={classes.button} disabled={isFirstElement}
                    onClick={() => props.onBack()}>
              Anterior
              {/*<Icon className={classes.rightIcon} icon={ic_send}/>*/}
            </Button>
            <Button style={{backgroundColor:  isValidate() ? config[props.app].color : ''}} variant="contained" color="primary" className={classes.button} disabled={!isValidate() || field_type=="calculation"}
                    onClick={() => props.onClick()} >
              { !isLastElement ? 'Siguiente' : 'Terminar'}
              {/*<Icon className={classes.rightIcon} icon={ic_send}/>*/}
            </Button>

          </div>
        </div>
      </>
    );
}

BasicForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BasicForm);
