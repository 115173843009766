import React, { Component } from 'react';
// import connect from "react-redux/es/connect/connect";
// import Notification from "../../Components/Notification";
import style from 'styled-components';
import {Redirect} from 'react-router-dom';
import {AppBar, Toolbar, Typography, withStyles} from "@material-ui/core";
import useScrollTrigger from "@material-ui/core/useScrollTrigger/useScrollTrigger";
import Slide from "@material-ui/core/Slide";
import Container from "@material-ui/core/Container";
import config from "../../config/config";
import AlertDialogAgreement from "../../Components/AlertDialogAgreement";
import {connect} from "react-redux";

const Span = style('span')`
    padding-bottom:50px;

`;
const styles = {
    main: {
        'padding-bottom':'60px',
    }
}

function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({ target: window ? window() : undefined });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}
class Main extends Component {
    constructor(props){
        super(props);
    }

    render() {

        const {mode, location, classes, agreement} = this.props;

        console.log("test", agreement)
        return (

                <Span>
                    <AlertDialogAgreement open={agreement} html={agreement}/>
                    <main className={classes.main}>
                        {this.props.children}
                    </main>


                    {mode === null && location.pathname !== '/' && <Redirect to={'/'}/>}

                    {/*<Notification/>*/}
                </Span>
        )
    }
}

// const mapStateToProps = ({ mode }) => {
//     return { mode } ;
// };


// export default connect(mapStateToProps, null)(Main);

function mapStateToProps(state) {
  return {
    agreement: state.agreement,
  };
}

export default connect(mapStateToProps, null)(withStyles(styles)(Main));




