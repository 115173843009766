import React, { useState, forwardRef } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {connect} from "react-redux";
import {setAgreement} from "../../js/actions";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AlertDialogAgreement({ open, handleClose, html, setAgreement }) {



  return (
    <form>
      <Dialog
        fullScreen
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <sm style={{ fontSize: '1.5rem', color: '#3f51b5' }}>Consentimiento Informado</sm>
        </DialogTitle>
        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column', // Organiza los hijos verticalmente.
            justifyContent: 'space-between'
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={() => setAgreement("")}>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    setAgreement: agreement => dispatch(setAgreement(agreement))
  };
}
export default connect(null, mapDispatchToProps)(AlertDialogAgreement);
