import React, {useEffect, useState} from "react"
import Container from "@material-ui/core/Container";
import Resource from "../../Components/Resource";
import {Fab, Paper} from "@material-ui/core";
import CardMenu from "../../Components/CardMenu";
import axios from "axios";
import config from "../../config/config";
import {bodyGet} from "../../utils/utils";
import * as Md from 'react-icons-kit/md/'
import { Icon } from 'react-icons-kit'
import {Autocomplete} from "@material-ui/lab";
import InputAdornment from "@material-ui/core/InputAdornment";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import TextField from '@mui/material/TextField';

const data = [
  {
    "color": "#499660",
    "title": "Samuel Salas",
    "icon": "https://www.sspa.juntadeandalucia.es/servicioandaluzdesalud/hhuuvr/innovacion/app/files/icons/global/carpeta.png",
    "redirect": "/files/177",
    "fecha": "23/11/2022"
  },
  {
    "color": "#499660",
    "title": "Jesus Moneno",
    "icon": "https://www.sspa.juntadeandalucia.es/servicioandaluzdesalud/hhuuvr/innovacion/app/files/icons/global/pdf.png",
    "redirect": "https://www.sspa.juntadeandalucia.es/servicioandaluzdesalud/hhuuvr/innovacion/app/files/welcome/principal/2022%20PLAN%20ACOGIDA%20PROFESIONAL%20HUVM%20GENERAL.pdf",
    "fecha": "23/11/2022"
  },
  {
    "color": "#499660",
    "title": "Otro",
    "icon": "https://www.sspa.juntadeandalucia.es/servicioandaluzdesalud/hhuuvr/innovacion/app/files/icons/global/INFORMACIÓN.png",
    "redirect": "/chat/morenojesus30c",
    "fecha": "23/11/2022"
  }
]

const top100Films = [
  { label: 'The Shawshank Redemption', year: 1994 },
  { label: 'The Godfather', year: 1972 },
  { label: 'The Godfather: Part II', year: 1974 },
  { label: 'The Dark Knight', year: 2008 },
  { label: '12 Angry Men', year: 1957 }
];
const ChatMenu = ({app, ...props}) => {

  const [menu, setMenu] = useState([]);
  const [found, setFound] = useState([]);
  const [input, setInput] = useState("");

  const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
    backgroundColor: config[app].color,
    color: "white"
  };

  const loadChatMenu = () => {
    return axios.post(config.apiUrl, bodyGet("OPERACION_MPROF_MENU"))
      .then(res => {
        const response = res.data.children.map(transformResponse)
        setMenu(response)})

  }

  const transformResponse = element => {

    return {
      title: element.nombre,
      description: element.mensaje,
      redirect: element.href,
      icon: element.icon ? element.icon : "https://cdn-icons-png.flaticon.com/512/811/811476.png"
    }
  }

  const transformUserResponse = element => {
    return {
      title: element.cuenta_usuario,
      description: element.nombre_completo,
      redirect: "/chatp/"+element.cuenta_usuario,
      icon: element.icon ? element.icon : "https://cdn-icons-png.flaticon.com/512/811/811476.png"
    }
  }

  useEffect(() => {
      loadChatMenu()
    }, [])

  const loadSearch = (event) => {
    setInput(event.target.value)
    if(event.target.value) {
      axios.post(config.apiUrl, bodyGet("OPERACION_MPROF_SEARCH", {nombre_usuario: event.target.value}))
        .then(res => {
          const result = res.data.usuarios.map(transformUserResponse)
          setFound(result)
        })
    }
    else
      setFound([])

  }

  console.log("props", app, props)
  return <Container maxWidth="md" style={{texAlign: {'text-align': 'center','margin-top': '15px'} }}>
    <TextField
      style={{margin: "10px"}}
      onKeyUp={loadSearch}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <AccountCircle />
          </InputAdornment>
        ),
      }}
    />

    {menu.filter(element => element.title.includes(input)).map((button, index) => <CardMenu key={`button${index}`} history={props.history} app={app} {...button}/>)}
    {found.map((button, index) => <CardMenu key={`button${index}`} history={props.history} app={app} {...button}/>)}

  </Container>

}


export default ChatMenu;