import React, {useEffect, useRef} from 'react';
import TextField from '@material-ui/core/TextField';
import {withStyles} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import './style.css';
import {connect} from "react-redux";
import {CircularProgress} from "@mui/material";

const styles = theme => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
});

// const formula = "str2int(form.getFieldValue('Peso_actual'))? + str2int(form.getFieldValue('Altura_actual'))"

const transformFormula = (formula) => {
  let formula1 = formula.replaceAll("form.", "");

  // return formula1.replaceAll("?", "");

  return formula1;
}

const str2int = (str) =>{
  return str == '' ? 0:parseInt(str); //SUBIR A PRODUCCIÓN
};

const Calculation = ({label, sections, next, calculation, ...props}) => {

  const calculateInput = useRef();
  const getFieldValue = (id) => {
  //Necesitamos buscar en el formulario
    const form = sections;

    const section = form.filter(sec =>  0 < sec.elementos.filter(o => o.item_name === id).length)
    const elements = section[0] ? section[0].elementos.filter(o => o.item_name === id) : [];
    const element = elements.length === 0 ? "" : elements[0].value;
    return element;
  }

  props.value = eval(transformFormula(calculation))

    useEffect(() => {
        const event = {
            target: calculateInput.current,
            value: props.value
        };
        props.onChange(event)
        next()
    }, [label]);


  return (
    <div className={"divQuestion"}
         style={{visibility: "hidden"}}
    >
      <b>{label}</b>
      <h1>{eval(transformFormula(calculation))}</h1>
      <TextField
         // onAnimationEnd={(event) => {
         //   props.onChange(event);
         //   next();
         // }}
         autoComplete="off"
         {...props}
         id={props.id}
         inputRef={calculateInput}
         InputLabelProps={{ shrink: !!props.value || props.focus }}
         margin="normal"/>
    </div>
  )
};
function mapStateToProps(state) {
  return {
    sections: state.form
  };
}
export default withStyles(styles)(connect(mapStateToProps)(Calculation))