import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import './style.css';


const styles = theme => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing.unit * 3,
  },
  texAlignLeft: {
    'text-align': 'left'
  }
});


// const isSelected = (value, option) => value.split(',').includes(option);
const isSelected = (value, option) => value.includes(option);

export default withStyles(styles)(({...props}) => {


  return (
    <div className={"divQuestion"}>
      <b>{props.label}</b>
        <FormGroup>
          {props.options.map((option, index) =>
            <FormControlLabel className={props.classes.texAlignLeft}
              key={`option-${index}`}
              control={<Checkbox onChange={props.onChange} checked={isSelected(props.value, option.db_value)} value={option.db_value}/>}
              label={option.display_value}
            />
          )}
        </FormGroup>
        {/*<FormHelperText>Be careful</FormHelperText>*/}
    </div>

  )
});
