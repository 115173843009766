import React from 'react';
// import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import ReactDom from 'react-dom';

const OriginalIp = '0.0.0.0';

class IpField extends React.Component{
    constructor(props){
        super(props);

        this.state={
            value: this.props.value || '',
            positionStart: 0,
            positionEnd: 0,
            error: null
        };

        this.labelRef = null
    }

    componentDidMount(){
        this.labelRef= ReactDom.findDOMNode(this.labelRef);
    }

    componentDidUpdate(prevProps,prevState,prevContext){
        if(this.props.value !== prevProps.value && this.props.value !== this.state.value) {
            this.setState({
                value: this.props.value
            })
        }
    }

    findInsertedChar = (newValue) => {
        const oldValue = this.state.value;
        for(let i=0; i < newValue.length; i++) {
            if(oldValue[i] !== newValue[i]){
                return newValue[i];
            }
        }
    };

    checkIpDots = (value) =>{
        let valueChecked = '';
        let subString = '';
        let stringsCounted = 0;
        let dotCounter = 0;

        for(let i=0; i < value.length; i++) {
            if(value[i] === '.' && dotCounter < 3) {
                if(value[i-1] && valueChecked[i-1]!=='.') {
                    subString='';
                    valueChecked += '.';
                    ++dotCounter;
                }
                stringsCounted = 0;
            }
            else {
                ++stringsCounted;
                if(stringsCounted === 3) {
                    subString += value[i];
                    if(parseInt(subString) > 255 )
                        this.setState({
                            error: 'You cannot enter a value greater than 255'
                        });
                    else {
                        if(value[i] !== '.')
                            valueChecked += value[i];
                        this.setState({
                            error: null
                        });

                        if (!(value[i-1] && value[i+1] === '.') && dotCounter < 3) {
                            valueChecked += '.';
                            stringsCounted = 0;
                            subString=''
                        }
                    }
                }
                else
                if (dotCounter < 4 && subString.length < 3) {
                    if(value[i] !== '.') {
                        valueChecked += value[i];
                        subString += value[i];
                    }
                }
            }
        }
        return valueChecked;
    };

    handleChange = (e) => {
        const oldValue = this.state.value;
        const value = e.target.value;
        if (oldValue.length > value.length) { //Borrar
            this.setState({
                value: e.target.value
            }, this.sendValue)
        }
        else if (oldValue.length < value.length) { //Control
            const newChar = this.findInsertedChar(value);
            if (!Number.isNaN(parseInt(newChar)) || newChar === '.') {
                const value = this.checkIpDots( e.target.value );
                if(value !== this.state.value)
                    this.setState({
                        value: value
                    }, this.sendValue)
            }

        }
    };

    sendValue = () =>{
        const e = {
            target:{
                name: this.props.name,
                value: this.state.value
            }
        };
        this.props.onChange(e)
    };

    render(){
        const {value, error } = this.state;
        const {label, fullWidth} = this.props;

        return (
          <div className={"divQuestion"}>
              <b>{label}</b>
                <OutlinedInput
                    placeholder = {OriginalIp}
                    id="component-outlined"
                    onChange={this.handleChange}
                    value={value}
                    labelWidth={this.labelRef ? this.labelRef.offsetWidth : 0}
                />
                {error && <p style={{color:'#f44336', fontSize:'0.75em', marginTop: 8, marginLeft: 12, marginBottom:0}}>{error}</p>}
            </div>
        )

    }
}

export default IpField