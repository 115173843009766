import React from 'react';
import ButtonsRectangular from "../../Components/ButtonsRectangular";
import store from "../../js/store";
import { connect } from "react-redux";
import { loadSection } from "../../js/actions/index";
import axios from "axios";
import config from "../../config/config";
import {bodyGet} from "../../utils/utils";
import {loadForm} from "../../js/actions";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import List from "@material-ui/core/List";
import CardMenu from "../../Components/CardMenu";
import Container from "@material-ui/core/Container";


class MenuFiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = { file: { children:[] }};
  }

  // componentDidUpdate(prevProps) {
  //   // // Uso tipico (no olvides de comparar los props):
  //   // if (this.props.files !== prevProps.files) {
  //   //   this.setState({files: this.props.files})
  //   // }
  // }

  componentWillMount() {
    this.getFile();
  }

  getFile(){
    const idFile = this.props.fileId;
    return axios.post(config.apiUrl, bodyGet("OPERACION_GET_FILES", {idFile: idFile}))
        .then(res => {
            console.log(res);
            this.setState({file:res.data})
          return res;
        })
        .catch(err => {
          console.log('Error:', err);
          localStorage.removeItem('token_patient');
          this.props.history.push(this.getRoute('/login'));
          return null;
        })
  }

  getRoute = (path, query='') => `${path}?app=${this.props.app}${!query ? '' : `&${query}`}`;

  getPropsButtonsRectangular = (file) => {
      const properties = {
          text:file.name,
          data:file,
          colorIcon:'',
          icon: !file.icon ? 'ic_clear':file.icon,
          href: !file.href ? '' : this.getRoute(file.href)
      };
      return properties
  }


  render() {

    return (
      <Container maxWidth="sm">
        <div style={{paddingTop: "24px"}}>

          {
            // this.state.file.children.map((file, index) =>
            //     <ButtonsRectangular
            //         key={`file-${index}`}
            //         id={index}
            //         {...this.getPropsButtonsRectangular(file)}
            //     />
            // )

            this.state.file.children.map((button, index) => {
              console.log(button)
              return <CardMenu
                key={`button${index}`}
                ref={`button${index}`}
                history={this.props.history}
                description={button.name}
                redirect={button.href}
                app={this.props.app}
                {...button}/>
            })

          }
        </div>
      </Container>
    );
  }
}

function mapStateToProps(state, props) {
  return {
      fileId: props.match.params.id
  };
}
function mapDispatchToProps(dispatch) {
  return {};
}

const defaultFiles = {
      name:'Root',
      children:[
            {
                name:'Primera carpeta',
                icon:'',
                color:'yellow',
                href:'/files/2'
            },
            {
                name:'Vídeo',
                icon:'ic_play_arrow',
                color:'red',
                href:'/video'
            },
            {
                name:'Documento',
                icon:'ic_play_arrow',
                color:'red',
                href:'/document/12'
            },
            {
                name:'PDF',
                icon:'',
                color:'green'
            },
            {
                name:'Texto',
                icon:'',
                color:'blue'
            }
        ]
  };

export default connect(mapStateToProps, mapDispatchToProps)(MenuFiles);
