import React from 'react';
import TextField from '@material-ui/core/TextField';
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import {withStyles} from "@material-ui/core";
import './style.css';


const styles = theme => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
});

export default withStyles(styles)(({label, ...props}) => (
  <div className={"divQuestion"}>
    <b>{label}</b>
    <TextField
        autoComplete="off"
        autoFocus
        {...props}
        type={'number'}
        margin="normal"
        InputLabelProps={{ shrink: !!props.value || props.focus }}
    />
  </div>
));
