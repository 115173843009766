import React from 'react';
import {Redirect} from 'react-router-dom';
import { withStyles, Grid, TextField, Button, FormControlLabel, Checkbox, Avatar } from '@material-ui/core';
import {Face, Lock, Visibility, VisibilityOff,Phone, Sms} from '@material-ui/icons';
import axios from 'axios';
import config from "../../config/config";
import Image from "react-bootstrap/es/Image";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {bodyGet} from "../../utils/utils";
import Alert from '@material-ui/lab/Alert';



const styles = () => ({
    maxSize: {
      'max-width': '20rem',
    },
    center: {
        display: 'flex',
        flex: 1,
        'align-items': 'center',
        'justify-content': 'center'
    },
    avatar: {
        margin: 10,
    },
    bigAvatar: {
        margin: 10,
        width: 80,
        height: 80,
    },
    imageLogin: {
        maxWidth: '120%',
        marginBottom: '3rem',
        marginTop: '2rem'

    }

});

class Ending extends React.Component {

    state = {};

    constructor(props) {
        super(props);
    }



  loginSystem(){
    const system = !config[this.props.app].systemUser ? "cronicare" : config[this.props.app].systemUser;
    const systemPass = "telemedicina_1";

    return axios.post(config.apiUrl,{
      tipo: "OPERACION_AUTH_PROY_MOV",
      usuario: system,
      passwd: systemPass
    })
      .then(res => {
        console.log(res);
        this.setState({token: res.data.token})
      })
      .catch(err => {
        console.log('Error:', err);
        this.setState({err:true});
        return null;
      })
  }

  getRoute = (path, query='') => `${path}?app=${this.props.app}${!query ? '' : `&${query}`}`;


    closeWindow = () => {
      window.open("about:blank", "_self");
      window.close();
    }


    render() {
        const { classes, app } = this.props;
        const logo = 'https://img.freepik.com/free-vector/business-success-concept-illustration_1133-823.jpg';
        // const logo = 'https://img.freepik.com/free-vector/verified-concept-illustration_114360-4998.jpg';
        return (
          <div className={classes.center}>
              <div className={classes.maxSize}>
                  <Grid container justify="center" alignItems="center">
                      <Image alt="Forgot Password" src={logo} className={classes.imageLogin} />
                  </Grid>
                  <Grid container justify="center" alignItems="center">
                      <h4>Cuestionario Completado</h4>
                  </Grid>

                  <Grid container justify="center" alignItems="center">
                      <p>Gracias por completar nuestro formulario.</p>
                  </Grid>

                <Grid container justify="center" alignItems="center">
                  <Button variant="contained" size="large" color="primary" onClick={this.closeWindow}>
                    Salir
                  </Button>
                  </Grid>


              </div>
          </div>


        );
    }
}

export default withStyles(styles)(Ending);
