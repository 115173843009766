import React from 'react'
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ReactDOM from "react-dom";
import './style.css';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class SelectSimple extends React.Component {
    constructor(props){
        super(props);
        this.InputLabelRef = null;
        this.state={
            labelWidth:0,
            options: this.props.options,
        }
    }


    componentDidMount() {
        this.setState({
            labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth
        });
    }


    handleChange = (e) => {
      this.props.onChange(e)
    };

    render(){
        const { name, label, value, className} = this.props;
        const { options, labelWidth} = this.state;

        return (
          <div className={"divQuestion"}>
              <b>{label}</b>
                <Select
                    value={value}
                    onChange={this.handleChange}
                    input={
                        <OutlinedInput
                            labelWidth={labelWidth}
                            name={name}
                            id={`outlined-age-simple-${name}`}
                        />
                    }
                    MenuProps={MenuProps}
                >
                    {options.map(option =>(
                        <MenuItem value={option.db_value}>{option.display_value}</MenuItem>
                    ))}

                </Select>
            </div>
        )
    }
}

export default SelectSimple;
