import React from 'react'
import TextField from './TextField'
import SelectField from './SelectField'
import IpField from './IpField'
import Number from './Number'
import Radio from './Radio'
import MultiSelectField from './MultiSelectField'
import Date from './Date'
import Table from './Table'
import Checkbox from './Checkbox'
import Textarea from "./TextArea";
import Time from "./Time"
import File from "./File"
import ImageSelector from "./ImageSelector";
import Slider from "./Slider";
import Info from "./Info";
import Calculation from "./Calculation";


export default ({type, dataType, ...rest}) =>{
    type = type ? type.toUpperCase() : type;
    type = type !== 'TEXT' ? type : dataType.toUpperCase();
    switch (type){
        case 'text'.toUpperCase():
        case 'st'.toUpperCase():
            return <TextField {...rest} />;
        case 'textarea'.toUpperCase():
            return <Textarea {...rest} />;
        case 'single-select'.toUpperCase():
            return <SelectField {...rest}/>;

        case 'multiselect'.toUpperCase():
            return <MultiSelectField {...rest}/>;

        case 'ip'.toUpperCase():
            return <IpField {...rest}/>;

        case 'number'.toUpperCase():
        case 'real'.toUpperCase():
            return <Number {...rest}/>;

        case 'radio'.toUpperCase():
            return <Radio {...rest}/>;

        case 'date'.toUpperCase():
            return <Date {...rest}/>;

        case 'table'.toUpperCase():
            return <Table {...rest}/>;

        case 'checkbox'.toUpperCase():
            return <Checkbox {...rest}/>;

        case 'time'.toUpperCase():
            return <Time {...rest}/>;

        case 'file'.toUpperCase():
            return <File {...rest}/>;

        case 'imageselector'.toUpperCase():
            return <ImageSelector {...rest}/>;

        case 'slider'.toUpperCase():
            return <Slider {...rest}/>;

        case 'info'.toUpperCase():
            return <Info {...rest}/>;

        case 'calculation'.toUpperCase():
            return <Calculation {...rest}/>;

        default:
            return <span>{type}</span>;
            // return <TextField {...rest}/>
    }
}
