import React from 'react';
import {Redirect} from 'react-router-dom';
import { withStyles, Grid, TextField, Button, FormControlLabel, Checkbox, Avatar } from '@material-ui/core';
import {Face, Lock, Visibility, VisibilityOff,Phone, Sms} from '@material-ui/icons';
import axios from 'axios';
import config from "../../config/config";
import Image from "react-bootstrap/es/Image";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import utils, {bodyGet} from "../../utils/utils";
import Alert from '@material-ui/lab/Alert';



const styles = () => ({
    maxSize: {
      'max-width': '20rem',
    },
    center: {
        display: 'flex',
        flex: 1,
        'align-items': 'center',
        'justify-content': 'center'
    },
    avatar: {
        margin: 10,
    },
    bigAvatar: {
        margin: 10,
        width: 80,
        height: 80,
    },
    imageLogin: {
        maxWidth: 240
    }

});

class ForgotPassword extends React.Component {

    state = {};

    constructor(props) {
        super(props);
        this.state = {
            phone: "",
            code: "",
            newPassword:"",
            confirmPassword: "",
            user: "",
            showPassword: false,
            step: 0,
            responseBack: {},
            error: "",
            token: null,
            token_paciente: null
        }

    }

    sendPhone = () => {
        return axios.post(config.apiUrl,{
            tipo: "OPERACION_FORGOT_1",
            phone: this.state.phone,
            userSign: utils.getUserSign()
        })
          .then(res => {
              return res;
          })

    }




    validateForm() {
      let validated = true;
      if(this.state.phone.length == 9 && this.state.step == 0) {
          validated = false;
      }
      if(this.state.code.length > 4 && this.state.step == 1)
          validated = false;
      if(this.state.newPassword.length > 5 && this.state.confirmPassword.length && this.state.confirmPassword ===  this.state.newPassword && this.state.step == 2)
          validated = false;

      return validated;
    }

  onSavePassword = () =>{
    if(this.state.newPassword.length <6)
      this.setState({error: 'La contraseña debe contener al menos 6 caracteres'})
      else if(this.state.confirmPassword != this.state.newPassword){
        this.setState({error: 'Las contraseñas no coinciden'})
      }
      else {
        this.setState({error: ''})
        this.save()
          .then((res)=>{
              console.log(res)
          })

      }
  }
  loginSystem(){
    const system = !config[this.props.app].systemUser ? "cronicare" : config[this.props.app].systemUser;
    const systemPass = "telemedicina_1";

    return axios.post(config.apiUrl,{
      tipo: "OPERACION_AUTH_PROY_MOV",
      usuario: system,
      passwd: systemPass
    })
      .then(res => {
        console.log(res);
        this.setState({token: res.data.token})
      })
      .catch(err => {
        console.log('Error:', err);
        this.setState({err:true});
        return null;
      })
  }

  getRoute = (path, query='') => `${path}?app=${this.props.app}${!query ? '' : `&${query}`}`;

  save(){
    return axios.post(config.apiUrl,{tipo: "OPERACION_CHANGE_PWD", newPassword: this.state.newPassword, "nombre_paciente": this.state.responseBack.nombre_paciente, id_estudio: this.state.responseBack.id_estudio, paciente: this.state.responseBack.id_usuario, token: this.state.token, token_paciente: this.state.token_paciente, usuario: config[this.props.app].systemUser})
      .then(res => {
        console.log('ChangePWD',res);
        if(res.data.codigo == "300")
          this.setState({error: "La contraseña no es válida"})
        else
        {
          this.setState({step: 3})
          setTimeout(() => {
            this.props.history.push(this.getRoute('/login'));
          }, 3000)
        }
        return res;
      })
      .catch(err => {
        console.log('Error:', err);
        this.setState({error: 'Error al guardar'})
        return null;
      })
  }

    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    handleSubmit = () => async event => {
      //Todo acción a realizar en el submit
      console.log('data: ', this.state.email, this.state.password);
      await this.loginSystem();
      const data = await this.loginUser(this.state.email, this.state.password);

      if(!data.err) {
          event.preventDefault();
          this.setState({redirect:true});
      }
    };

    nextStep = () => {
        if(this.state.step == 0){
          this.loginSystem()
            axios.post(config.apiUrl, {tipo: "OPERACION_RC", telefono: this.state.phone, id_usuario_sistema: config[this.props.app].systemUser})
              .then(res => {
                  console.log(res)

                  if(res.data.codigo == "300")
                    this.setState({error: "El número no corresponde a ningún paciente registrado"})
                  else{
                    this.setState({error: ""})

                    this.setState({responseBack: {"nombre_paciente": res.data.nombre_paciente, id_estudio: res.data.id_estudio, id_usuario: res.data.id_usuario}})
                    this.setState({step: this.state.step + 1})
                  }

              })
              .catch(err => {
                  console.log('Error:', err);
                  // localStorage.removeItem('token_patient');
                  // this.props.history.push(this.getRoute('/login'));
              })
        }
        if(this.state.step == 1){
            axios.post(config.apiUrl, { tipo: "OPERACION_RC_CHECK", codigo: this.state.code, "nombre_paciente": this.state.responseBack.nombre_paciente, id_estudio: this.state.responseBack.id_estudio, id_usuario: this.state.responseBack.id_usuario})
              .then(res => {
                if(res.data.codigo == "300")
                  this.setState({error: "Código incorrecto"})
                else {
                  this.error = ""
                  this.setState({error: ""})
                  this.setState({token_paciente: res.data.token})
                  this.setState({step: this.state.step + 1})
                }
              })
              .catch(err => {
                  console.log('Error:', err);
                  // localStorage.removeItem('token_patient');
                  // this.props.history.push(this.getRoute('/login'));
              })
        }
        if(this.state.step == 2){
          this.onSavePassword();
        }
    }


    handleClickShowPassword = () => {
        this.setState({showPassword: !this.state.showPassword});
    };


    render() {
        const { classes, app } = this.props;
        const logo = 'https://image.freepik.com/vector-gratis/ilustracion-icono-seguridad-nube_53876-6325.jpg';
        return (
          this.state.redirect ? <Redirect to={`/menu${this.props.location.search}`}/>:
          <div className={classes.center}>
              <div className={classes.maxSize}>
                  <Grid container justify="center" alignItems="center">
                      <Image alt="Forgot Password" src={logo} className={classes.imageLogin} />
                  </Grid>
                  <Grid container justify="center" alignItems="center">
                      <h4>Recordar Contraseña</h4>
                  </Grid>
                  {this.state.step == 0 && <>
                          <Grid container justify="center" alignItems="center">
                              <p>Introduzca el teléfono asociado a su usuario</p>
                          </Grid>
                          <Grid container spacing={2} alignItems="flex-end">
                              <Grid item>
                                  <Phone />
                              </Grid>
                              <Grid item md={true} sm={true} xs={true}>
                                  <TextField id="phone" label="Teléfono" type="number" fullWidth autoFocus
                                             value={this.state.phone}
                                             onChange={this.handleChange('phone')}
                                             required />
                              </Grid>
                          </Grid>
                      </>
                  }
                  {this.state.step == 1 && <>
                      <Grid container justify="center" alignItems="center">
                          <p>Introduzca el código indicado en el SMS recibido</p>
                      </Grid>
                      <Grid container spacing={2} alignItems="flex-end">
                          <Grid item>
                              <Sms />
                          </Grid>
                          <Grid item md={true} sm={true} xs={true}>
                              <TextField id="code" label="Código" type="number" fullWidth autoFocus
                                         value={this.state.code}
                                         onChange={this.handleChange('code')}
                                         required />
                          </Grid>
                      </Grid>
                  </>
                  }
                  { this.state.step == 2 && <>
                      <Grid container spacing={2} alignItems="flex-end">
                          <Grid item>
                              <Face />
                          </Grid>
                          <Grid item md={true} sm={true} xs={true}>
                              <TextField id="username" label="Usuario" type="text" fullWidth
                                         value={this.state.responseBack.nombre_paciente}
                                         disbaled
                                         // onChange={this.handleChange('user')}
                                          />
                          </Grid>
                      </Grid>
                      <Grid container spacing={2} alignItems="flex-end">
                          <Grid item>
                              <Lock />
                          </Grid>
                          <Grid item md={true} sm={true} xs={true}>
                              <TextField
                                id="outlined-adornment-password"
                                type={this.state.showPassword ? 'text' : 'password'}
                                label="Nueva Contraseña"
                                value={this.state.newPassword}
                                onChange={this.handleChange('newPassword')}
                                required
                                InputProps={{
                                    endAdornment: (
                                      <InputAdornment>
                                          <IconButton
                                            edge="end"
                                            aria-label="Toggle password visibility"
                                            onClick={this.handleClickShowPassword}
                                          >
                                              {this.showPassword ? <VisibilityOff /> : <Visibility />}
                                          </IconButton>
                                      </InputAdornment>
                                    ),
                                }}
                              />
                          </Grid>
                      </Grid>
                      <Grid container spacing={2} alignItems="flex-end">
                          <Grid item>
                              <Lock />
                          </Grid>
                          <Grid item md={true} sm={true} xs={true}>
                              <TextField
                                id="outlined-adornment-password"
                                type={this.state.showPassword ? 'text' : 'password'}
                                label="Repetir Contraseña"
                                value={this.state.confirmPassword}
                                onChange={this.handleChange('confirmPassword')}
                                required
                                InputProps={{
                                    endAdornment: (
                                      <InputAdornment>
                                          <IconButton
                                            edge="end"
                                            aria-label="Toggle password visibility"
                                            onClick={this.handleClickShowPassword}
                                          >
                                              {this.showPassword ? <VisibilityOff /> : <Visibility />}
                                          </IconButton>
                                      </InputAdornment>
                                    ),
                                }}
                              />
                          </Grid>
                      </Grid>
                  </>
                  }



                  {!this.state.error ? console.log('eeeeee'):
                      <Grid>
                          <p style={{color: 'red'}}>{this.state.error}</p>
                      </Grid>
                  }
                {this.state.step < 3 ? <Grid container justify="center" style={{marginTop: '10px'}}>
                  <Button variant="outlined"
                          fullWidth={true}
                          color="primary"
                          style={{textTransform: "none", marginTop: '1.5em'}}
                          disabled={!!this.validateForm()}
                          onClick={this.nextStep}>
                    {this.state.step != 2 ? 'Siguiente' : 'Finalizar'}
                  </Button>
                </Grid> : null}

                {this.state.step == 3 ? <Alert
                  action={
                    <Button color="inherit" size="small">
                      Entrar
                    </Button>
                  }
                >
                  La contraseña ha sido cambiada
                </Alert>:null}
              </div>
          </div>


        );
    }
}

export default withStyles(styles)(ForgotPassword);
