import React, { Component } from 'react';
import './style.css';
import {AppBar, Toolbar, Typography, IconButton, MenuItem, Menu, Badge, withStyles, Fab} from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import config from '../../config/config';
import {Link} from "react-router-dom";
import TemporaryDrawer from "../TemporaryDrawer";
import Header from "../../App";

// import connect from "react-redux/es/connect/connect";
// import { getMode } from "../../Services/Redux/actions";

const styles = theme => ({
    root: {
        width: '100%',
    },
    grow: {
        flexGrow: 1,
    },

    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing.unit * 2,
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing.unit * 3,
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing.unit * 9,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 10,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    appBar: {
        top: 'auto',
        bottom: 0,
    },
});



class NavBar extends Component {

    state = {
        anchorEl: null,
        mobileMoreAnchorEl: null,
    };

    constructor(props) {
        super(props);
    }

    getRoute = (path, query='') => `${path}?app=${this.props.app}${!query ? '' : `&${query}`}`;

    getDirectionRoute = () => {
        return this.getRoute('/menu')
    }


    handleProfileMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
        this.handleMobileMenuClose();
    };

    handleMobileMenuOpen = event => {
        this.setState({ mobileMoreAnchorEl: event.currentTarget });
    };

    handleMobileMenuClose = () => {
        this.setState({ mobileMoreAnchorEl: null });
    };

    isNotMenu = () => {
        return this.props.location.pathname === '/menu';
    };

    render() {
        const { app, classes } = this.props;
        const logo = !app ? require('../../images/heartPlus.png') : require (`../../images/${app}-nav.png`);
        const { anchorEl, mobileMoreAnchorEl } = this.state;
        const isMenuOpen = Boolean(anchorEl);
        const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

        const renderMenu = (
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={this.handleMenuClose}
          >
              <MenuItem onClick={this.handleMenuClose}>Profile</MenuItem>
              <MenuItem onClick={this.handleMenuClose}>My account</MenuItem>
          </Menu>
        );

        const renderMobileMenu = (
          <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={this.handleMobileMenuClose}
          >
              <MenuItem>
                  <IconButton color="inherit">
                      <Badge badgeContent={4} color="secondary">
                          <MailIcon />
                      </Badge>
                  </IconButton>
                  <p>Messages</p>
              </MenuItem>
              <MenuItem>
                  <IconButton color="inherit">
                      <Badge badgeContent={11} color="secondary">
                          <NotificationsIcon />
                      </Badge>
                  </IconButton>
                  <p>Notifications</p>
              </MenuItem>
              <MenuItem onClick={this.handleProfileMenuOpen}>
                  <IconButton color="inherit">
                      <AccountCircle />
                  </IconButton>
                  <p>Profile</p>
              </MenuItem>
          </Menu>
        );

        return (
          <div className={classes.root}>
              <AppBar position="static" style={{ background: config[app].color, color: config[app].fontcolor }}>
                  <Toolbar>
                      {this.isNotMenu() ?
                          <TemporaryDrawer/>
                          :
                          <IconButton className={classes.menuButton} color="inherit" component={Link} to={this.getDirectionRoute()} aria-label="Open drawer">
                              <MenuIcon />
                          </IconButton>
                      }
                      <Typography  variant="h6" color="inherit" noWrap>
                          {config[app].name}
                      </Typography>
                      <div className={classes.grow} />

                      <div className={classes.sectionMobile}>
                          <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">
                              <MoreIcon />
                          </IconButton>
                      </div>
                  </Toolbar>
              </AppBar>
              {renderMenu}
              {renderMobileMenu}
          </div>
        );


        //   (
        //     <nav className={`navbar navbar-expand navbar-light bg-light bg-${app}`}>
        //         <Link className={'navbar-brand'} to={this.getRoute('/')}><img src={logo} className={'p1'} alt=""/></Link>
        //         <button className="navbar-toggler" type="button" data-toggle="collapse"
        //                 data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
        //                 aria-expanded="false" aria-label="Toggle navigation">
        //             <span className="navbar-toggler-icon"/>
        //         </button>
        //         <div className="collapse navbar-collapse" id="navbarSupportedContent">
        //             <ul className="navbar-nav ">
        //                 <li className="nav-item mr-3 ">
        //                     <Link className="fake-link" to={this.getRoute('/login')} >Login</Link>
        //                 </li>
        //                 <li className="nav-item mr-3">
        //                     <Link className="fake-link" to={this.getRoute('/login')}>Page</Link>
        //                 </li>
        //                 {/*<li className="nav-item mr-3">*/}
        //                     {/*<Link className="fake-link" to={this.getRoute('/?app=hipertension&')}>Hipertension</Link>*/}
        //                 {/*</li>*/}
        //             </ul>
        //         </div>
        //     </nav>
        // );
    }
}


// const mapStateToProps = ({ mode, downloaded }) => {
//     return { mode, downloaded } ;
// };



// export default connect(mapStateToProps)(NavBar);
// export default connect(mapStateToProps, { getMode })(NavBar);
export default withStyles(styles)(NavBar);

