import React from 'react';
import axios from "axios";
import config from "../../config/config";
import {bodyGet} from "../../utils/utils";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import Card from "@material-ui/core/es/Card/Card";
import CardHeader from "@material-ui/core/es/CardHeader/CardHeader";
import CardMedia from "@material-ui/core/es/CardMedia/CardMedia";
import CardContent from "@material-ui/core/es/CardContent/CardContent";
import Typography from "@material-ui/core/es/Typography/Typography";
import Avatar from "@material-ui/core/es/Avatar/Avatar";
import { Icon } from 'react-icons-kit'
import * as Md from 'react-icons-kit/md/';
import ButtonsRectangular from "../MenuSections";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import { clearBreadCrumbs} from "../../js/actions";

const styles = theme => ({
  root: {
    display: 'flex',
  },
  recommendation: {
    margin: theme.spacing.unit * 3,
  },
  card: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: 'red',
  },
});


class Recommendation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {recommendations: []}
    this.props.clearBreadCrumbs();
  };

  componentDidMount() {
    this.getRecommendation();
  }

  getRoute = (path, query='') => `${path}?app=${this.props.app}${!query ? '' : `&${query}`}`;

  getRecommendation(){
    return axios.post(config.apiUrl, bodyGet("OPERACION_7_MOV", {form: this.props.formId, elementos: [], completed:true}))
        .then(res => {
          console.log('Respuesta',res);
          if(!!res.data.recomendaciones){
            this.setState({recommendations: res.data.recomendaciones})
            if(this.state.recommendations.length == 0){
              this.props.history.push(this.getRoute('/menu'))
            }
          }
          return res.data.recomendaciones;
        })
        .catch(err => {
          console.log('Error:', err);
          return null;
        })
  }

  render() {
    const {classes} = this.props;

    return (
      <>
        {
          this.state.recommendations.map((recomendation, index) =>
              <div className={classes.recommendation} key={'recommendation'+index}>
                <Card className={classes.card}>
                  <CardHeader
                      avatar={
                        <Avatar aria-label="recipe" className={classes.avatar}>
                          R
                          {/*<Icon size={'60%'} icon={Md['assigment']}/>*/}
                        </Avatar>
                      }
                      title={recomendation.titulo}
                      subheader={recomendation.subtitulo}
                  />
                  <CardContent>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {recomendation.descripcion}
                    </Typography>
                  </CardContent>
                </Card>
              </div>
          )
        }
      </>
    );
  }

}

const mapStateToProps = state => ({formId: state.formId});
const mapDispatchToProps = (dispatch) => (
    {
      clearBreadCrumbs: () => dispatch(clearBreadCrumbs())
    });

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(styles)(Recommendation));
