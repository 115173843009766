import React from 'react';
import { Line } from 'react-chartjs-2';
import Graphic from "../../Components/Graphic";
import Container from '@material-ui/core/Container';


class Charts extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data2: {
        labels: ['asd', 'asd', 'dasd', 'April', 'May', 'June', 'July', 'January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
          {
            label: 'My asd dataset',
            data: [65, 59, 80, 81, 56, 55, 40,65, 59, 80, 81, 56, 55, 40]
          }
        ]
      },
      data: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
          {
            label: 'My First dataset',
            data: [65, 59, 80, 81, 56, 55, 40,65, 59, 80, 81, 56, 55, 40, 59, 80, 81, 56, 55, 40]
          },
          {
            label: 'My second dataset',
            data: [11, 33, 44, 55, 66, 77, 88,65, 59, 80, 81, 56, 55, 40]
          }
        ]
      }
    }
  };

  render() {
    return (
        <Container maxWidth="md">
          <Graphic data={this.state.data} graphType={'line'}/>
          <Graphic data={this.state.data} graphType={'horizontal-bar'}/>
          <Graphic data={this.state.data} graphType={'radar'}/>
          <Graphic data={this.state.data2} graphType={'bar'}/>
          <Graphic data={this.state.data} graphType={'doughnut'}/>

        </Container>
  );
  }
}

export default Charts;
