import React, { Component } from 'react';
// import { Route, withRouter, BrowserRouter } from 'react-router-dom';
import { Route, withRouter } from 'react-router-dom';
import { Provider, connect } from 'react-redux';
import store from './js/store/index'
// import logo from './logo.svg';
import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';

/*** Components ***/
import Header from './Components/Header';
import NavBar from './Components/ResponsiveNavBar';
import LabelBottomNavigation from "./Components/LabelBottomNavigation";
import {Helmet} from "react-helmet";


/*** Scenes ***/
import Main from './Scenes/Main'
import Login from "./Scenes/Login";
import ForgotPassword from "./Scenes/ForgotPassword";
import Menu from "./Scenes/Menu";
import Forms from "./Scenes/Elements";
import Charts from "./Scenes/Charts";
import Video from "./Scenes/Video";
import MenuSection from "./Scenes/MenuSections";
import MenuFiles from "./Scenes/MenuFiles";

import Recommendation from "./Scenes/Recommendation";
import Calendar from "./Scenes/Calendar";
import PrivateRouter from "./Scenes/PrivateRouter";
import ElevateAppBar from "./Components/ElevateAppBar";
import Document from "./Scenes/Document";
import config from "./config/config";
import Chat from "./Scenes/Chat";
import Alarms from "./Scenes/Alarms";
import Ending from "./Scenes/Ending";
import ScanQr from "./Scenes/ScanQr";
import ScanUrl from "./Scenes/ScanUrl";
import ChatMenu from "./Scenes/ChatMenu";
import ChatPro from "./Scenes/ChatPro";
import MenuRepository from "./Scenes/MenuRepository";


class App extends Component {

  getApp = () => {
    if(!this.props.location || this.props.location.search === '') {
      return 'default';
    } else {
      const app = this.props.location.search.split('app=')[1];
      return !app ? 'default' : app.split('&')[0]
    }
  };

  isNotLogin = () => {
    //todo add real condition (TOKEN ?)
    // return this.props.location.pathname === '/' || this.props.location.pathname === '/login';
    return this.props.location.pathname === '/login' || this.props.location.pathname === '/element' || this.props.location.pathname === '/forgot-password' || this.props.location.pathname.split('-')[0] === '/open';

  };

  theme = createTheme({
    palette: {
      primary: {
        main: config[this.getApp()].color,
        contrastText: '#fff',
      },
    },
  });

  render() {

    const app = this.getApp();
    return (
        <Provider store={store}>
          <div className="App">
            <Helmet>
              <title>{config[app].name}</title>
              <meta name="theme-color" content={config[app].color}/>
            </Helmet>
              {this.isNotLogin() ? '' :
                  <>
                    <ElevateAppBar location={this.props.location} app={app} history={this.props.history} />
                  </>
                  }
            <ThemeProvider theme={this.theme}>
              <Main location={this.props.location} app={app} className={'main'}>
                {/*<Route path="/" component={ Main }  />*/}
                <Route exact path="/login" component={ (props) => <Login {... props} app={app}/> }/>
                <Route exact path="/forgot-password" component={ (props) => <ForgotPassword {... props} app={app}/> }/>
                <Route exact path="/open-sections" component={ (props) => <MenuSection {... props} app={app}/> }/>
                <Route exact path="/open-element" component={ (props) => <Forms {... props} app={app}/> }/>
                <Route exact path="/open-end" component={ (props) => <Ending {... props} app={app}/> }/>

                <PrivateRouter location={this.props.location} app={app}>
                  <Route exact path="/" component={ (props) =>  <Menu {... props} app={app}/>}/>
                  <Route exact path="/menu" component={ (props) => <Menu {... props} app={app}/>}/>
                  <Route exact path="/element" component={ (props) => <Forms {... props} app={app}/> }/>
                  <Route exact path="/sections/:id" component={ (props) => <MenuSection {... props} app={app}/> }/>
                  <Route exact path="/files/:id" component={ (props) => <MenuFiles {... props} app={app}/> }/>
                  <Route exact path="/repository/:id*" component={ (props) => <MenuRepository {... props} app={app}/> }/>
                  <Route exact path="/chat/:id" component={ (props) => <Chat {... props} app={app}/> }/>
                  <Route exact path="/chatp/:id" component={ (props) => <ChatPro {... props} app={app}/> }/>
                  <Route exact path="/chatmenu/" component={ (props) => <ChatMenu {... props} app={app}/> }/>
                  <Route exact path="/charts" component={ (props) => <Charts {... props} app={app}/>  }/>
                  <Route exact path="/calendar" component={ (props) => <Calendar {... props} app={app}/>  }/>
                  <Route exact path="/video" component={ (props) => <Video {... props} app={app}/>  }/>
                  <Route exact path="/document/:id" component={ (props) => <Document {... props} app={app}/>  }/>
                  <Route exact path="/recommendation" component={ (props) => <Recommendation {... props} app={app}/>  }/>
                  <Route exact path="/alarms" component={ (props) => <Alarms {... props} app={app}/>  }/>
                  <Route exact path="/scanqr" component={ (props) => <ScanQr {... props} app={app}/>  }/>
                  <Route exact path="/scanurl" component={ (props) => <ScanUrl {... props} app={app}/>  }/>
                </PrivateRouter>

              </Main>
            </ThemeProvider>
            {/*{this.isNotLogin()? '' : <LabelBottomNavigation className={'bottomNav'} />}*/}

            <div id={'Modals'}/>
          </div>
        </Provider>
    );
  }
}

export default withRouter(App);
// export default App;
