import React, {useEffect} from 'react';
import 'date-fns';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Switch from '@material-ui/core/Switch';
import AccessAlarm from '@material-ui/icons/AccessAlarm';
import Container from "@material-ui/core/Container";
import TextField from '@material-ui/core/TextField';
import moment from "moment"

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import axios from "axios";
import config from "../../config/config";
import {bodyGet} from "../../utils/utils";



const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

const exampleResponse = [
  {id: '1', time: '10:30', name: 'No tiene alarmas', active: true},
]

export default function Alarms() {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(['wifi']);
  const [selectedDate, setSelectedDate] = React.useState({});
  const [clock, setClock] = React.useState(false);
  const [elements, setElements] = React.useState(exampleResponse)

  useEffect(() => {
    getMessages().then(res => {
      const elm =  res.data.datos ? res.data.datos : exampleResponse
      setElements(elm)
    });
  }, [])


  const getMessages = () => {
    return axios.post(config.apiUrl, bodyGet("OPERACION_ALARMA"))
      .then(res => {
        return res

      })
      .catch(err => {
        console.log('Error:', err);
        localStorage.removeItem('token_patient');
        this.props.history.push(this.getRoute('/login'));
        return null;
      })
  }

  const handleToggle = (value) => () => {
    console.log(value)
    value.active = !value.active
    axios.post(config.apiUrl, bodyGet("OPERACION_ALARMA_GUARDAR", {alarm: value}))
      .then(res => {
        console.log(res)
      })
      .catch(err => {
        console.log('Error:', err);
        localStorage.removeItem('token_patient');
        this.props.history.push(this.getRoute('/login'));
      })
  };

  const openDatepicker = (value) => {

    setSelectedDate({id: value.id, time: new Date('2014-08-18T' + value.time)})
    setTimeout(() => {
      setClock(true)
    }, 10)
  };

  const saveTime = () => {
    console.log(selectedDate);
  }
  const closeClock = () => {
    setClock(false);
  }
  const handleDateChange = (date) => {
    console.log('chang', date)

    const minutes = (date.getMinutes()<10?'0':'') + date.getMinutes();
    console.log(minutes)
    const hours = (date.getHours()<10?'0':'') + date.getHours();

    let copyElements = JSON.parse(JSON.stringify(elements))
    copyElements.find((element) => element.id == selectedDate.id).time = hours+":"+minutes;
    const dataForSend = copyElements.find((element) => element.id == selectedDate.id);

    axios.post(config.apiUrl, bodyGet("OPERACION_ALARMA_GUARDAR", {alarm: dataForSend}))
      .then(res => {
        console.log(res)
        setElements(copyElements);


      })
      .catch(err => {
        console.log('Error:', err);
        localStorage.removeItem('token_patient');
        this.props.history.push(this.getRoute('/login'));
      })
  };

  return (
    <Container maxWidth="sm">
      <List subheader={<ListSubheader>Alarmas</ListSubheader>} className={classes.root}>
        {elements.map(element => <ListItem onClick={() => openDatepicker(element)}>
          {/*<ListItemIcon>*/}
            {/*<AccessAlarm />*/}
          {/*</ListItemIcon>*/}
          <ListItemText primary={element.time} secondary={element.name} style={{marginRight: '6px'}}/>
          <ListItemSecondaryAction>
            <Switch
              edge="end"
              defaultChecked={element.active}
              onChange={handleToggle(element)}
            />
          </ListItemSecondaryAction>
        </ListItem>)}
      </List>

      <MuiPickersUtilsProvider utils={DateFnsUtils} style={{visibility: 'hidden'}}>
        <KeyboardTimePicker
          style={{visibility: 'hidden'}}
          cancelLabel={'Cancelar'}
          onAccept={closeClock}
          onClose={closeClock}
          open={clock}
          margin="normal"
          id="time-picker"
          label="Time picker"
          ampm={false}
          value={selectedDate.time}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
        />
      </MuiPickersUtilsProvider>
    </Container>
  );
}