import React from "react";
import Button from "@material-ui/core/Button";


class Clock extends React.Component {
  format(time) {
    let seconds = time % 60;
    let minutes = Math.floor(time / 60);
    minutes = minutes.toString().length === 1 ? "0" + minutes : minutes;
    seconds = seconds.toString().length === 1 ? "0" + seconds : seconds;
    return minutes + ':' + seconds;
  }
  render () {
    const {time} = this.props;
    return (
      <div className="displayedTime">
        <h1>{this.format(time)}</h1>
      </div>
    )
  }
}

// class Button extends React.Component {
//   render() {
//     return (
//       <button onClick={this.props.onClickHandler}>{this.props.label}</button>
//     );
//   }
// }

class Countdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: this.props.time,
      running: false,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.running !== prevState.running){
      switch(this.state.running) {
        case true:
          this.handleStart();
      }
    }
  }

  handleStart() {
    this.timer = setInterval(() => {
      const newCount = this.state.count - 1;
      this.setState(
        {count: newCount >= 0 ? newCount : 0}
      );
    }, 1000);
  }

  handleStop() {
    if(this.timer) {
      clearInterval(this.timer);
      this.setState(
        {running:false}
      );
    }
  }

  handleReset() {
    this.setState(
      {count: this.props.time}
    );
  }

  handleCountdown(seconds) {
    this.setState({
      running: true
    })
  }

  render() {
    const {count} = this.state;
    return (
      <div className="container">
        <Clock time={count}/>
        <p>
          {!this.state.running ?
            <Button variant="outlined" onClick={this.handleCountdown.bind(this)}>Iniciar</Button>:
            <Button variant="outlined" onClick={this.handleStop.bind(this)}>Detener</Button>
          }
          <Button variant="outlined" onClick={this.handleReset.bind(this)}>Reiniciar</Button>
        </p>
      </div>
    )
  }
}
export default Countdown;
