import React, { Component } from 'react';
import {IconButton} from "@material-ui/core";
import ArrowBack from '@material-ui/icons/ArrowBack';
import Close from '@material-ui/icons/Close';
import {addBreadCrumbs, clearBreadCrumbs, deleteBreadCrumbs} from "../../js/actions";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import Utils from "../../utils/utils";



class IconAppBar extends Component{
    constructor(props){
        super(props);
    }

    getRoute = (path, query='') => `${path}?app=${this.props.app}${!query ? '' : `&${query}`}`;


    goBack = () => {
        if(this.props.breadCrumbs.length == 1 || this.props.breadCrumbs.length == 1){
            this.props.history.push(Utils.getRedirectWithApp("/"))
        }
        else{
            this.props.history.goBack();
        }
        this.props.deleteBreadCrumbs();
    };
    render() {
        return (
            <div>
                <IconButton color="inherit" onClick={this.goBack}>
                    {this.props.close ? <Close/> : <ArrowBack/>}
                </IconButton>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({ breadCrumbs: state.breadCrumbs});

const mapDispatchToProps = (dispatch) => (
    {
        deleteBreadCrumbs: () => dispatch(deleteBreadCrumbs()),
    });


export default connect(mapStateToProps, mapDispatchToProps)(IconAppBar);
