import React from 'react'
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import YouTube from 'react-youtube';
import {withStyles} from "@material-ui/core";
import Graphic from "../Graphic";
import Countdown from "../Countdown";

const styles = theme => ({
    texAlignLeft: {
        'text-align': 'left',
        'white-space': 'pre-line'
    }
});
export default withStyles(styles)(({type, content, url, graphType, app, ...props}) =>{
    const getRoute = (path, query='') => `${path}?app=${app}${!query ? '' : `&${query}`}`;

    const opts = {
        height: '200',
        width: '100%',
        playerVars: { // https://developers.google.com/youtube/player_parameters
            autoplay: 0
        }
    };

    switch (type) {
        case 'title':
            return <h4 className={props.classes.texAlignLeft}>{content}</h4>;
        case 'subtitle':
            return <h6 className={props.classes.texAlignLeft}>{content}</h6>;
        case 'string':
            return <p className={props.classes.texAlignLeft} dangerouslySetInnerHTML={{__html: content}}></p>;
        case 'image':
            return <img src={content} style={{'max-width':'90%'}} />;
        case 'video':
            return <YouTube videoId={content} opts={opts}/>;
        case 'internalLink':
            return <p><Button variant="contained" component={Link} to={getRoute(url)}>{content}</Button></p>;
        case 'externalLink':
            return <p><a href={url} target="_blank"><Button variant="contained" >{content}</Button></a></p>;
        case 'chart':
            return <Graphic data={content} graphType={graphType}/>
        case 'chrono':
            return <Countdown time={parseInt(content, 10)}/>
        default:
            return <span>{content}</span>;
    }
})
