import React from 'react'
import { Icon } from 'react-icons-kit'
import * as Md from 'react-icons-kit/md/'
import {Link} from "react-router-dom";
import Utils from "../../utils/utils";
import config from "../../config/config";

const styles = {
  card: {
    paddingLeft: "24px",
    marginBottom: "24px",
    alignItems: "center",
    cursor: "pointer",
    backgroundColor: "white",
    flexDirection: "row",
    display: "flex",
    paddingBottom: "24px",
    paddingTop: "24px",
    borderRadius: "16px",
    position: "relative"
  },
  icon: {
    height: "56px",
    width: "56px",
    marginRight: "16px",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f7f8f9",
    display: "flex",
    borderRadius: "16px"
  },
  imageIcon: {
    height: "36px",
    width: "36px",
  },
  divText: {
    maxWidth: "66%",
    alignItems: "stretch",
    backgroundColor: "rgba(0, 0, 0, 0)",
    border: "0px solid black",
    boxSizing: "border-box",
    display: "flex",
    flexBasis: "auto",
    flexDirection: "column",
    flexShrink: 0,
    listStyle: "none",
    padding: "0px",
    position: "relative",
    textAlign: "left"
  },
  title: {
    lineHeight: "24px",
    color:"rgb(26, 5, 29)",
    fontSize: "16px",
    textTransform: "uppercase",
  },
  subtitle: {
    marginTop: "3px",
    lineHeight: "20px",
    color: "rgb(109, 95, 111)",
    fontSize: "14px",
  },
  arrow: {
    color: "rgb(109, 95, 111)",
    position: "absolute",
    right: "16px",
  }
};

const CardMenu = ({history, redirect, title, description, icon, app, ...props}) => {
  const isIcon = icon.startsWith("ic_")
  const isExternal = redirect.substr(0, 4) == "http"

  return (
    <div style={styles.card} onClick={!isExternal ? () => history.push(Utils.getRedirectWithApp(redirect)) :  () => window.open(redirect, '_blank')}>
      <div style={styles.icon}>
        {!isIcon ? <img src={icon} width={"40px"}/> : <Icon icon={Md[icon]} className={"icon-width"} style={{color: config[app].color}}/>}
      </div>
      <div style={styles.divText}>
        <div style={styles.title}>
          {title}
        </div>
        <div style={styles.subtitle}>
          {description}
        </div>
      </div>
      <div style={styles.arrow} >
        <Icon icon={Md["ic_keyboard_arrow_right"]} />
      </div>
    </div>
  )

}

export default CardMenu;