import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import axios from "axios";
import config from "../../config/config";
import Utils, {bodyGet} from "../../utils/utils";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DescriptionIcon from '@material-ui/icons/Description';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class AlertDialogPWD extends React.Component {
    constructor(props) {
        super(props);
        this.state = { password: '', repeatPassword:'', error: '', fileExists: false, read: false, checked: false };
    }
    onSave = () =>{
      if (!this.state.checked && this.state.fileExists) {
        this.setState({ error: 'Debe dar su consentimiento para continuar' });
        return; // Detener la ejecución si el checkbox no está marcado
      }
        if(this.state.password.length <6)
          this.setState({error: 'La contraseña debe contener al menos 6 caracteres'})
        else if(this.state.repeatPassword != this.state.password){
          this.setState({error: 'Las contraseñas no coinciden'})
        }
        else {
            this.setState({error: ''})
            this.save()
                .then(()=>{
                    localStorage.removeItem('first_time');
                    this.props.handleClose();

                })

        }
    }
    save(){
        return axios.post(config.apiUrl, bodyGet("OPERACION_CHANGE_PWD", {newPassword: this.state.password}))
            .then(res => {
                console.log('ChangePWD',res);
                return res;
            })
            .catch(err => {
                console.log('Error:', err);
                this.setState({error: 'Error al guardar'})
                return null;
            })
    }
    myChangeHandlerPassword = (event) => {
        this.setState({password: event.target.value});
    }
    myChangeHandlerRepeatPassword = (event) => {
        this.setState({repeatPassword: event.target.value});
    }

    checkFileExistence = () => {
        const url = 'https://www.sspa.juntadeandalucia.es/servicioandaluzdesalud/hhuuvr/innovacion/app/files/consentimientos/'+Utils.getApp()+'.pdf';

        axios.head(url)
          .then(response => {
              // Si la solicitud es exitosa, asumimos que el archivo existe.
              this.setState({ fileExists: true });
          })
          .catch(error => {
              // Si hay un error (por ejemplo, respuesta 404), asumimos que el archivo no existe.
              console.error('Error checking file existence:', error);
              this.setState({ fileExists: false });
          });
    };

    componentDidMount() {
        this.checkFileExistence();
    }
    render() {
        const {open, handleClose} = this.props;
        return (
            <>
                <form>

                    <Dialog fullScreen
                        open={open}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                            style={{zIndex: 1299}}
                    >
                        <DialogTitle id="alert-dialog-slide-title">{'Cambio de contraseña'}</DialogTitle>
                        <DialogContent
                          style={{
                              display: 'flex',
                              flexDirection: 'column', // Organiza los hijos verticalmente.
                              justifyContent: 'space-between'
                          }}
                        ><div>
                            <DialogContentText id="alert-dialog-slide-description">
                                {'Es necesario que cambie su contraseña'}
                            </DialogContentText>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="password"
                                label="Nueva contraseña"
                                type="password"
                                fullWidth
                                onChange={this.myChangeHandlerPassword}
                            />
                            <TextField
                                margin="dense"
                                id="repeatPwd"
                                label="Repita contraseña"
                                type="password"
                                fullWidth
                                onChange={this.myChangeHandlerRepeatPassword}
                            />

                          <p style={{color: 'red'}}>{this.state.error}</p>

                        </div>
                        {this.state.fileExists && <div>
                            <h6 onClick={() => this.setState({read: true})}><a
                              href={'https://www.sspa.juntadeandalucia.es/servicioandaluzdesalud/hhuuvr/innovacion/app/files/consentimientos/' + Utils.getApp() + '.pdf'}
                              target="_blank" rel="noopener noreferrer"><DescriptionIcon/> Lea el consentimiento continuar</a></h6>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox
                                  color="primary"
                                  onClick={() => this.setState({checked: !this.state.checked})}
                                  disabled={!this.state.read}
                                />}

                                label={"Doy mi consentimiento para el tratamiento de mis datos personales para las finalidades descritas en este estudio de investigación"}
                              />
                            </FormGroup>
                          </div>}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.onSave} color="primary">
                                Guardar
                            </Button>
                        </DialogActions>
                    </Dialog>
                </form>
            </>
        );
    }
}

export default AlertDialogPWD;