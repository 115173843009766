import React from 'react';
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import Slider from '@material-ui/core/Slider';

import {withStyles} from "@material-ui/core";
import './style.css';

const styles = theme => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
});


export default withStyles(styles)(({label, ...props}) => {

  console.log(props)
  const handleChange = (event, newValue) => {
      props.onChange({target: {type: 'text', value: newValue} })
  };

  const marks = [
    {
      value: props.min != "" ? parseInt(props.min) : 0,
      label: props.min != "" ? props.min : 0
    },
    {
      value: props.max != "" ? parseInt(props.max) : 100,
      label: props.max != "" ? props.max : 100,
    },

  ];

  return (
    <div className={"divQuestion"}>
      <b>{label}</b>
      <Slider
        onChange={handleChange} value={props.value} defaultValue={0}
        aria-labelledby={props.max != "" ? "discrete-slider-custom" : "continuous-slider"}
        valueLabelDisplay={props.max != "" ? "auto" : "off"}
        marks={props.max != "" ? marks : undefined}
        min={props.min != "" ? parseInt(props.min) : 0}
        max={props.max != "" ? parseInt(props.max) : 100}
      />
    </div>)
  });
