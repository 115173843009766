import React, {useState} from 'react'
import { QrReader } from 'react-qr-reader';
import { Modal, Button, Typography } from '@mui/material';
import {Dialog, DialogTitle} from "@material-ui/core";
import DialogActions from '@mui/material/DialogActions';

import {Title} from "@material-ui/icons";
import config from "../../config/config";
import axios from "axios";
import Utils, {bodyGet} from "../../utils/utils";




const ScanQr = ({app, history, ...props}) => {

  const [scan, setScan] = useState(false)

  const [step, setStep] = useState(0)


  const bedId = Utils.getJsonSearch().bedId
  const askPermission = () => {
    const isChrome = /Chrome/.test(navigator.userAgent) || /Google Inc/.test(navigator.vendor);

    if(isChrome) {
      navigator.getUserMedia({video: true}, function (stream) {
        stream.getTracks().forEach(x => {
          x.stop()
          setStep(2)
        });
      }, err => console.log(err));
    }
    else {
      console.log('Tiene permisos')
      setStep(2)
    }

  }

  const sliderData = [
    {
      title: "Bienvenido",
      description: "A la APP para declarar su alta en el Hospital Universitario Virgen Macarena.",
      image: "/images/resources/Logo-HUVM.png",
      textButton: "Comenzar",
      action: ()=> setStep(1)
    },
    {
      title: "Active la cámara",
      description: "Para que podamos escanear su código QR será necesario que permita usar su cámara.",
      image: "/images/resources/camera-3.png",
      textButton: "Permitir Cámara",
      action: ()=> askPermission()
    },
    {
      title: "Escanee su código",
      description: "Pase la cámara por el código QR que aparece en su informe.",
      image: "/images/resources/scan.png",
      textButton: "Escanear",
      action: ()=> {setScan(true)}
    },
    {
      title: "Finalizado",
      description: "Su alta se ha efectuado correctamente.",
      image: "https://www.pngall.com/wp-content/uploads/12/Green-Check-PNG-Cutout.png",
      textButton: "Terminar",
      action: ()=> {history.push(Utils.getRedirectWithApp("/"))}
    },

  ]

  const sliderDataWithUrl = [
    {
      title: "Bienvenido",
      description: "A la APP para declarar su alta en el Hospital Universitario Virgen Macarena.",
      image: "/images/resources/Logo-HUVM.png",
      textButton: "Comenzar",
      action: ()=> setStep(1)
    },
    {
      title: "Va a proceder a notificar que se marcha",
      description: "¿Está seguro?",
      image: "/images/resources/scan.png",
      textButton: "Sí",
      action: ()=> {sendQrCode(bedId).then((res) =>  setStep(2))}
    },
    {
      title: "Finalizado",
      description: "Su alta se ha efectuado correctamente.",
      image: "https://www.pngall.com/wp-content/uploads/12/Green-Check-PNG-Cutout.png",
      textButton: "Terminar",
      action: ()=> {history.push(Utils.getRedirectWithApp("/"))}
    },

  ]

  const sendQrCode = (data) => {
    return axios.post(config.apiUrl, bodyGet("OPERACION_QR", {qr: data, origen: "paciente"}))
      .then(res => {
        if(!!res.data){
          setScan(false);
          setStep(3);
        }
      })
      .catch(err => {
        console.log('Error:', err);
        return null;
      })
  }

  const SliderView = ({title, description, image, textButton, action}) => (
    <div>
      <div style={{minHeight: "20rem", marginTop: "1.9rem"}}>
        <img
          style={{ 'maxWidth': "20rem" }}
          preview={false}
          src={image}
        />
      </div>

      <Typography style={{textAlign: "center", marginTop: "3rem", marginBottom: "2rem"}}>
        <h5>{title}</h5>
        {description}
      </Typography>
      <Button style={{backgroundColor: config[app].color, width: "80%", maxWidth: "400px"}} variant="contained"  onClick={action}>{textButton}</Button>
    </div>

  )


  return (
  <>
    {!bedId ? <SliderView {...sliderData[step]}></SliderView> : <SliderView {...sliderDataWithUrl[step]}></SliderView> }

    { scan ?
    <Dialog open={scan} fullWidth={true}>
       <QrReader
         constraints={{facingMode: {exact: "environment"}}}
         onResult={(result, error) => {
          if (!!result) {
            sendQrCode(Utils.getBedId(result.text)).then(()=> {
                setScan(false)
                setStep(3)
              }
            )
          }

          if (!!error) {
            console.info(error);
          }
        }}
        style={{ width: '100%' }}
      />
      <DialogActions>
        <Button autoFocus onClick={()=> setScan(false)}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
      : null}

  </>
  )
}

export default ScanQr