import React, { Component } from 'react';
import PropTypes from 'prop-types';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import {Badge, IconButton} from "@material-ui/core";
import config from "../../config/config";
import MailIcon from '@material-ui/icons/Mail';


import './style.css';
import {AppBar, Toolbar, Typography, MenuItem, Menu, withStyles, Fab} from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import {ExitToApp} from '@material-ui/icons';

import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import {Link} from "react-router-dom";
import TemporaryDrawer from "../TemporaryDrawer";
import IconAppBar from "../IconAppBar";
import Login from "../../App";
import {connect} from "react-redux";

const styles = theme => ({
    root: {
        width: '100%',
    },
    grow: {
        flexGrow: 1,
    },

    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing.unit * 2,
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing.unit * 3,
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing.unit * 9,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 10,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    appBar: {
        top: 'auto',
        bottom: 0,
    },
    texMenu:{
        marginBottom: 0,
    }
});

function ElevationScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

class ElevateAppBar extends Component {
    state = {
        anchorEl: null,
        mobileMoreAnchorEl: null,
    };
    constructor(props) {
        super(props);
    }

    getRoute = (path, query='') => `${path}?app=${this.props.app}${!query ? '' : `&${query}`}`;

    getDirectionRoute = () => {
        return this.getRoute('/menu')
    }


    handleProfileMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
        this.handleMobileMenuClose();
    };

    handleMobileMenuOpen = event => {
        this.setState({ mobileMoreAnchorEl: event.currentTarget });
    };

    handleMobileMenuClose = () => {
        this.setState({ mobileMoreAnchorEl: null });
    };

    isNotMenu = () => {
        return (this.props.location.pathname === '/menu' || this.props.location.pathname === '/');
    };

    isChat = () => {
        console.log(this.props.location.pathname)
        return (this.props.location.pathname.split('/')[1] === 'chat');
    };

    leaveSession = () => {
        this.props.history.push(this.getRoute('/login'));
        localStorage.removeItem('token_patient');
    }

    render() {
        const { app, classes } = this.props;
        const logo = !app ? require('../../images/heartPlus.png') : require (`../../images/${app}-nav.png`);
        const { anchorEl, mobileMoreAnchorEl } = this.state;
        const isMenuOpen = Boolean(anchorEl);
        const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

        const renderMenu = (
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMenuOpen}
                onClose={this.handleMenuClose}
            >
                <MenuItem onClick={this.handleMenuClose}>Profile</MenuItem>
                <MenuItem onClick={this.handleMenuClose}>My account</MenuItem>
            </Menu>
        );

        const renderMobileMenu = (
            <Menu
                anchorEl={mobileMoreAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMobileMenuOpen}
                onClose={this.handleMobileMenuClose}
            >
                {/*<MenuItem>*/}
                    {/*<IconButton color="inherit">*/}
                        {/*<Badge badgeContent={4} color="secondary">*/}
                            {/*<MailIcon />*/}
                        {/*</Badge>*/}
                    {/*</IconButton>*/}
                    {/*<p>Messages</p>*/}
                {/*</MenuItem>*/}
                {/*<MenuItem>*/}
                    {/*<IconButton color="inherit">*/}
                        {/*<Badge badgeContent={11} color="secondary">*/}
                            {/*<NotificationsIcon />*/}
                        {/*</Badge>*/}
                    {/*</IconButton>*/}
                    {/*<p>Notifications</p>*/}
                {/*</MenuItem>*/}
                <MenuItem onClick={this.leaveSession}>
                    <ExitToApp color="inherit">
                        <AccountCircle />
                    </ExitToApp>
                    <p className={classes.texMenu}>Cerrar Sesión</p>
                </MenuItem>
            </Menu>
        );

        return(
        <React.Fragment>
            <ElevationScroll {...this.props} >
                    <AppBar style={{background: config[app].color, color: config[app].fontcolor, 'z-index': '1300', borderRadius: "0 0 16px 16px"}}>
                        <Toolbar style={{minHeight: "64px"}}>
                            {this.isNotMenu()?
                                <TemporaryDrawer {...this.props}/>
                                :
                                <IconAppBar {...this.props}/>
                            }
                            {/*<IconAppBar app={this.props.app}/>*/}

                            <div style={{width: "100%", display: "flex", alignContent: "stretch", justifyItems: "center", justifyContent: "center"}}>
                                <Typography  variant="h6" color="inherit" style={{fontWeight: "bold"}} >
                                    {this.isChat() ?
                                      this.props.chatName
                                      :
                                      config[app].name
                                    }
                                </Typography>
                            </div>
                            <div className={classes.grow} />

                            <div className={classes.sectionMobile}>
                                <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">
                                    {/*<Badge badgeContent={5} color="secondary">*/}
                                     <AccountCircle />
                                    {/*</Badge>*/}
                                </IconButton>
                            </div>
                        </Toolbar>
                        {renderMenu}
                        {renderMobileMenu}

                    </AppBar>
            </ElevationScroll>
            <Toolbar style={{minHeight: "64px"}}/>
        </React.Fragment>);

    }
}

// export default function ElevateAppBar(props) {
//     return (
//         <React.Fragment>
//             <CssBaseline />
//             <ElevationScroll {...props}>
//                 <AppBar>
//                     <Toolbar>
//                         <Typography variant="h6">Scroll to Elevate App Bar</Typography>
//                     </Toolbar>
//                 </AppBar>
//             </ElevationScroll>
//             <Toolbar />
//             <Container style={{'display':'none'}}>
//                 <Box my={2}>
//                     {[...new Array(12)]
//                         .map(
//                             () => `a`,
//                         )
//                         .join('\n')}
//                 </Box>
//             </Container>
//         </React.Fragment>
//     );
// }
function mapStateToProps(state, props) {
    return {
        chatName: state.chatName
    };
}
export default connect(mapStateToProps) (withStyles(styles)(ElevateAppBar));