import React from 'react';
import { Line, HorizontalBar, Radar, Bar, Doughnut } from 'react-chartjs-2';
import Container from '@material-ui/core/Container';


class Graphic extends React.Component {

  constructor(props) {
    super(props);

  };

  typeOfGraph = () => {
    const {data, graphType} = this.props;

    let dataAux = JSON.parse(JSON.stringify(data));
    // colores por defecto
    const colors = ['rgb(114, 148, 203, 0.2)', 'rgb(211, 92, 95, 0.2)', 'rgb(132, 187, 91, 0.2)'];
    const borderColors = ['rgb(114, 148, 203)', 'rgb(211, 92, 95)', 'rgb(132, 187, 91)'];
    // Opciones
    const options = {responsive: true, maintainAspectRatio: false}
    const height = 250;
    if(graphType != "doughnut") {
      dataAux.datasets.map((dataset, index) => {
        dataset.borderWidth = 1;
        if (!dataset.backgroundColor) {
          dataset.backgroundColor = colors[index];
          dataset.borderColor = borderColors[index];
        }
      });
    }
    else {
      dataAux.datasets[0].backgroundColor = borderColors;
    }

    switch (graphType){
      case 'line':
        return <Line  options={options} height={height} data={dataAux} />;
      case 'horizontal-bar':
        return <HorizontalBar options={options} height={height}  data={dataAux} />;
      case 'radar':
        return <Radar options={options} height={height}  data={dataAux} />;
      case 'bar':
        return <Bar options={options} height={height}  data={dataAux} />;
      case 'doughnut':
        return <Doughnut options={options} height={height}  data={dataAux} />;
      default:
        return <line options={options} height={height}  data={dataAux} />;
    }
  }

  render() {
    return (<div>{this.typeOfGraph()}</div>);
  }
}

export default Graphic;
