import React from 'react';
import Container from '@material-ui/core/Container';

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import AlertDialogPWD from "../../Components/AlertDialogPWD";
import axios from "axios";
import config from "../../config/config";
import Utils, {bodyGet} from "../../utils/utils";
import {connect} from "react-redux";
import {loadMainMenu, setAgreement} from "../../js/actions";
import CardMenu from "../../Components/CardMenu";
import {Skeleton} from "@mui/material";



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
      <Typography
          component="div"
          role="tabpanel"
          hidden={value !== index}
          id={`full-width-tabpanel-${index}`}
          aria-labelledby={`full-width-tab-${index}`}
          {...other}
      >
        <Box p={3}>{children}</Box>
      </Typography>
  );
}

class Menu extends React.Component {

  constructor(props) {
    super(props);
    const today = new Date();
    const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
    this.state = {
      tabsIndex:0,
      buttons:[],
      openDialog: localStorage.getItem('first_time') === 'true',
      loading: true
    }
    this.getMenu();
  }





  handleClose = () => this.setState({openDialog: false});


  getMenu(){
    return axios.post(config.apiUrl, bodyGet("OPERACION_MENU", Utils.getJsonSearch()))
        .then(res => {
            if(!!res.data){
                this.setState({buttons: res.data.children})
                console.log(res.data.mainMenu);
                console.log(res.data)
                this.props.loadMainMenu(res.data.main_menu)
                if (this.state.openDialog)
                  this.props.setAgreement(res.data.ci)

            }

        else
                this.setState({buttons: []})
        })
        .catch(err => {
          console.log('Error:', err);
          localStorage.removeItem('token_patient');
          this.props.history.push(Utils.getRedirectWithApp('/login'));
          return null;
        })
        .finally(() => {
            this.setState({loading: false})
        })
  }

  render() {
    const name_patient = !localStorage.getItem('name_patient') ? 'usuario' : localStorage.getItem('name_patient');

    return (
        <>
            <Container maxWidth="sm">
              <div className={"MuiContainer-maxWidthSm"} style={{textAlign: "left", marginLeft: "24px", marginTop: "24px"}}>
                <div style={{fontWeight: 500, fontSize: 20, marginBottom: "5px"}}>
                  ¡Hola <span style={{'text-transform': 'capitalize'}}>{name_patient.toLowerCase()}!</span>
                </div>

                <div style={{marginBottom: "20px", color: "rgb(150, 150, 150)", fontWeight: 400}}>
                  ¿Cómo estás hoy?
                </div>

              </div>

                {this.state.loading && <Typography variant="h1">
                    <Skeleton/>
                    <Skeleton/>
                    <Skeleton/>
                </Typography>}

              {
                this.state.buttons.map((button, index) =>  <CardMenu key={`button${index}`} ref={`button${index}`} history={this.props.history} app={this.props.app} {...button}/>)
              }

            </Container>


          <AlertDialogPWD
              open={this.state.openDialog}
              handleClose={this.handleClose}
          />

        </>
    );
  }
}
function mapStateToProps(state, props) {
    return {
        mainMenu: state.mainMenu
    };
}
function mapDispatchToProps(dispatch) {
    return {
        loadMainMenu: mainMenu => dispatch(loadMainMenu(mainMenu)),
        setAgreement: agreement => dispatch(setAgreement(agreement))

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
