// import connect from "react-redux/es/connect/connect";
import React, { Component } from 'react';
import Styled from 'styled-components';
// import {HorizontalLoading} from "../Loader";

const HeaderStyled = Styled.header`
`;

class Header extends Component {
    render() {
        return (
            <HeaderStyled>
                {this.props.children}
                {/*<HorizontalLoading charge={this.props.requestStatus} height={'3px'} bgcolor={'rgb(194, 71, 52)'} />*/}
            </HeaderStyled>
        )
    }
}


// const mapStateToProps = ({ requestStatus }) => {
//     return { requestStatus } ;
// };


// export default connect(mapStateToProps, null )(Header);

export default Header;