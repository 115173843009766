import Container from "@material-ui/core/Container";
import CardMenu from "../../Components/CardMenu";
import AlertDialogPWD from "../../Components/AlertDialogPWD";
import React from "react";


const ScanUrl = (props) => {
  const menu = [
    {
      color: "#499660",
      description: "Me han dado el alta y ya me voy",
      fecha: "14/10/2022",
      icon: "https://cdn-icons-png.flaticon.com/512/5233/5233959.png",
      redirect: "/scanqr",
      title: "Dejar habitación"
    },
    {
      color: "#499660",
      description: "Obtener información relacionada con el hospital",
      fecha: "14/10/2022",
      icon: "https://cdn-icons-png.flaticon.com/512/2785/2785491.png",
      redirect: "/",
      title: "Explorar hospital"
    },
  ]

  console.log(props)

  return (
      <>
        <Container maxWidth="sm">
          <div className={"MuiContainer-maxWidthSm"} style={{textAlign: "left", marginLeft: "24px", marginTop: "24px"}}>
            <div style={{fontWeight: 500, fontSize: 20, marginBottom: "5px"}}>
              ¡Hola!
            </div>
            <div style={{marginBottom: "20px", color: "rgb(150, 150, 150)", fontWeight: 400}}>
              ¿Qué desea hacer?
            </div>

          </div>

          {
            menu.map((button, index) => <CardMenu key={`button${index}`}
                                                                history={props.history}
                                                                app={props.app} {...button}/>)
          }

        </Container>

      </>
    );
}

export default ScanUrl