import {Fab, withStyles} from "@material-ui/core";
import { Icon } from 'react-icons-kit'
import * as Md from 'react-icons-kit/md/'
import React from "react";
import {Link} from "react-router-dom";

const styles = theme => ({
  fab: {
    margin: theme.spacing.unit,
    width: '70px',
    height: '70px'
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
});

class CustomButton extends React.Component {
  render() {
    const { classes, label, color, icon, href, download } = this.props;
    return (
      download ?
          <div>
            <a download target="_blank" href={'https://helpx.adobe.com/pdf/adobe_reader_reference.pdf'}>
              <Fab color={color} aria-label={label} className={classes.fab} >
                <Icon size={'60%'} icon={Md[icon]}/>
              </Fab>
                <p>{label}</p>
            </a>
          </div>
        :
          <div>
            <Fab color={color} aria-label={label} className={classes.fab} component={Link} to={href}>
              <Icon size={'60%'} icon={Md[icon]}/>
            </Fab>
              <p>{label}</p>
          </div>
    );
  }
}

export default withStyles(styles)(CustomButton);
