import React from 'react';
import {Redirect} from 'react-router-dom';
import { withStyles, Grid, TextField, Button, FormControlLabel, Checkbox, Avatar } from '@material-ui/core';
import {Face, Lock, Visibility, VisibilityOff} from '@material-ui/icons';
import axios from 'axios';
import config from "../../config/config";
import Image from "react-bootstrap/es/Image";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import NavigationIcon from '@material-ui/icons/AddToHomeScreen';
import Fab from '@material-ui/core/Fab';
import utils, {bodyGet} from "../../utils/utils";

const styles = () => ({
    center: {
        display: 'flex',
        flex: 1,
        height: '30em',
        'align-items': 'center',
        'justify-content': 'center'
    },
    avatar: {
        margin: 10,
    },
    bigAvatar: {
        margin: 10,
        width: 80,
        height: 80,
    },
    imageLogin: {
        maxWidth: 240
    },
    installButton: {
        position: 'fixed',
        bottom: '1rem',
        right: '1rem',

    }

});

class Login extends React.Component {

    state = {};

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            showPassword: false,
            deferredPrompt: null,
            showInstallButton: false,
            loggedBefore: false
        }
    }


    componentDidMount() {
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            this.setState({ deferredPrompt: e, showInstallButton: true });
        });

        this.getLoggedBefore()

    }

    getLoggedBefore(){

        return axios.post(config.apiUrl, bodyGet("OPERACION_LOGUEADO", {userSign: utils.getUserSign()}))
            .then(res => {
                this.setState({loggedBefore: res.data.loggedBefore})
                return true;
            })
            .catch(err => {
                console.log('Error:', err);
                return null;
            })
    }


    installApp = () => {
        // Mostrar el prompt de instalación
        const { deferredPrompt } = this.state;
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('El usuario aceptó la instalación');
                } else {
                    console.log('El usuario rechazó la instalación');
                }
                this.setState({ deferredPrompt: null, showInstallButton: false });
            });
        }
    }
  getRoute = (path, query='') => `${path}?app=${this.props.app}${!query ? '' : `&${query}`}`;


  loginSystem(){
        const system = !config[this.props.app].systemUser ? "cronicare" : config[this.props.app].systemUser;
        const systemPass = "telemedicina_1";

        return axios.post(config.apiUrl,{
            tipo: "OPERACION_AUTH_PROY_MOV",
            usuario: system,
            passwd: systemPass
        })
            .then(res => {
                console.log(res);
                localStorage.setItem('system', system);
                localStorage.setItem('token_system', res.data.token);
                localStorage.setItem('id', res.data.id);
                return res;
            })
            .catch(err => {
                console.log('Error:', err);
                this.setState({err:true});
                return null;
            })
    }

    loginUser(user, pass){
        const internalAuth = config[this.props.app].internalAuth ? config[this.props.app].internalAuth : false

        console.log('loginUser', user, pass);
        return axios.post(config.apiUrl,{
            tipo: "OPERACION_AUTH_MOV",
            nombre_paciente: user.toLowerCase(),
            token_sistema: localStorage.getItem('token_system'),
            pass_paciente: pass,
            new_pass_paciente: "",
            token_paciente: "",
            id_usuario_sistema: localStorage.getItem('id'),
            internal_auth: internalAuth,
            firebase_token: localStorage.getItem('notification-token'),
            userSign: utils.getUserSign()
        })
            .then(res => {

                console.log(res);
                localStorage.setItem('patient', res.data.nombre_paciente);
                localStorage.setItem('token_patient', res.data.token_paciente);
                localStorage.setItem('name_patient', res.data.nombre)
                localStorage.setItem('id_center', res.data.id_centro);
                localStorage.setItem('id_essay', res.data.id_estudio);
                localStorage.setItem('id_patient', res.data.id_paciente);
                if(pass.split('_')[0] === 'telemedicina' || pass === "1234")
                    localStorage.setItem('first_time', 'true');
                return res;
            })
            .catch(err => {
                console.log('Error:', err);
                this.setState({err:true});
                this.setState({loggedBefore: false})
                return {err:'mal'};
            })
    }

    validateForm() {
      return this.state.email.length <= 0 && this.state.password.length <= 0;
    }

    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    handleSubmit = () => async event => {
      //Todo acción a realizar en el submit
      console.log('data: ', this.state.email, this.state.password);
      await this.loginSystem();
      const data = await this.loginUser(this.state.email, this.state.password);

      if(!data.err) {
          event.preventDefault();
          this.setState({redirect:true});
      }
    };


    handleClickShowPassword = () => {
        this.setState({showPassword: !this.state.showPassword});
    };

    redirectToForgotPassword = () => {
      this.props.history.push(this.getRoute('/forgot-password'));
    }

    handleMouseDownPassword = event => {
        event.preventDefault();
    };

    render() {
        const { classes, app } = this.props;
        const internalAuth = config[this.props.app].internalAuth ? config[this.props.app].internalAuth : false
        const logo = !app ? require('../../images/heartPlus.png') : require (`../../images/${app}-nav.png`);
        return (
          this.state.redirect ? <Redirect to={`/menu${this.props.location.search}`}/>:
          <div className={classes.center}>
              <div>
                  <Grid container justify="center" alignItems="center">
                      <Image alt="Remy Sharp" src={logo} className={classes.imageLogin} />
                  </Grid>
                  {/*<Grid container justify="center" alignItems="center">*/}
                      {/*<h4>{config[app].name}</h4>*/}
                  {/*</Grid>*/}
                  <Grid container spacing={2} alignItems="flex-end">
                      <Grid item>
                          <Face />
                      </Grid>
                      <Grid item md={true} sm={true} xs={true}>
                          <TextField id="username" label="Usuario/DNI" type="text" fullWidth autoFocus
                                     value={this.state.email}
                                     onChange={this.handleChange('email')}
                                     required />
                      </Grid>
                  </Grid>
                  {this.state.loggedBefore ? "" : <Grid container spacing={2} alignItems="flex-end">
                      <Grid item>
                          <Lock/>
                      </Grid>
                      {/*<Grid item md={true} sm={true} xs={true}>*/}
                      {/*<TextField id="pass" label="Contraseña" type="password" fullWidth*/}
                      {/*value={this.state.password}*/}
                      {/*onChange={this.handleChange('password')}*/}
                      {/*required />*/}
                      {/*</Grid>*/}
                      <Grid item md={true} sm={true} xs={true}>
                          <TextField
                              id="outlined-adornment-password"
                              type={this.state.showPassword ? 'text' : 'password'}
                              label="Contraseña"
                              value={this.state.password}
                              onChange={this.handleChange('password')}
                              required
                              InputProps={{
                                  endAdornment: (
                                      <InputAdornment>
                                          <IconButton
                                              edge="end"
                                              aria-label="Toggle password visibility"
                                              onClick={this.handleClickShowPassword}
                                          >
                                              {this.showPassword ? <VisibilityOff/> : <Visibility/>}
                                          </IconButton>
                                      </InputAdornment>
                                  ),
                              }}
                          />
                      </Grid>
                  </Grid>}
                  <Grid container alignItems="center" justify="space-between">
                      <Grid item>
                          {/*<FormControlLabel control={*/}
                              {/*<Checkbox*/}
                                {/*color="primary"*/}
                              {/*/>*/}
                          {/*} label="Remember me" />*/}
                      </Grid>
                    {
                      !internalAuth && <Grid item>
                          <Button disableFocusRipple disableRipple style={{ textTransform: "none" }} variant="text" color="primary" onClick={this.redirectToForgotPassword}>Recordar Contraseña</Button>
                      </Grid>
                    }
                  </Grid>
                  {!this.state.err ? console.log('eeeeee'):
                      <Grid>
                          <p style={{color: 'red'}}>El usuario o la contraseña no son válidos</p>
                      </Grid>
                  }
                  <Grid container justify="center" style={{ marginTop: '10px' }}>
                      <Button variant="outlined"
                              fullWidth={true}
                              color="primary"
                              style={{ textTransform: "none" }}
                              disabled={!!this.validateForm()}
                              onClick={this.handleSubmit()}>
                        Iniciar sesión
                      </Button>
                  </Grid>

                  {this.state.showInstallButton && (
                      <Fab variant="extended" className={classes.installButton} onClick={this.installApp}>
                          <NavigationIcon className={classes.extendedIcon} />
                          Instalar APP
                      </Fab>
                  )}
              </div>
          </div>
        );
    }
}

export default withStyles(styles)(Login);
