// src/js/constants/action-types.js
export const ADD_ARTICLE = "ADD_ARTICLE";
export const LOAD_FORM = "LOAD_FORM";
export const LOAD_FORM_ID = "LOAD_FORM_ID";
export const LOAD_SECTION = "LOAD_SECTION";
export const ADD_BREAD_CRUMBS = "ADD_BREAD_CRUMBS";
export const CLEAR_BREAD_CRUMBS = "CLEAR_BREAD_CRUMBS";
export const DELETE_BREAD_CRUMBS = "DELETE_BREAD_CRUMBS";
export const LOAD_MAIN_MENU = "LOAD_MAIN_MENU";
export const OPEN_MODAL = "OPEN_MODAL";
export const CHANGE_NAME = "CHANGE_NAME";
export const SET_AGREEMENT = "SET_AGREEMENT";


