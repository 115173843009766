import React from 'react';
import BasicForm from "../../Components/BasicForm";
import store from "../../js/store/index";
import { Prompt } from 'react-router-dom';

import { Redirect } from 'react-router-dom'
import axios from "axios";
import config from "../../config/config";
import {bodyGet} from "../../utils/utils";

import Container from '@material-ui/core/Container';
import CustomizedSnackbars from "../../Components/CustomizedSnackbars";
import {Grid} from "@material-ui/core";
import {connect} from "react-redux";
import {openModal} from "../../js/actions";
import GenericSnackbar from "../../Components/GenericSnackbar";
import * as qs from "qs";
import AlertDialogSlide from "../../Components/AlertDialogSlide";
import utils from "../../utils/utils";
import {CircularProgress} from "@mui/material";


const elementosDefault =[
  {
    "item_name": "",
    "left_item_text": "",
    "right_item_text": "",
    "units": "",
    "field_type": "Datos no encontrados",
    "data_type": "2-line",
    "value": "",
    "default_value": "",
    "column_number": "3",
    "calculation": "",
    "display_status": "",
    "conditional_display": "++++ Fecha    1"
  }
]
//Variables para la creación de rutas
const [id_essay, id_patient] = [localStorage.getItem("id_essay"), localStorage.getItem("id_patient")];

class Elements extends React.Component {

  constructor(props) {
    super(props);
    console.log('Crea element');

    // console.log('asd', window.topicText);
    const elements = this.getElements();
    this.state = {
      elementos: elements,
      elementSelected: elements[0],
      index: 0,
      redirect:false,
      error: '',
      canLeave: false,
      recommendations: 0,
      openInitialDialog: false,
      loading: false,
    };
  }

  componentDidMount() {
    //todo Compruebo si el primer elemento debe mostrar según su condición
    if (this.state.elementos[0].field_type != "Datos no encontrados")
      this.compareConditionalFirstElement(this.state.elementos[0])
    if(this.getJsonSearch().opentoken){
      this.setState({openInitialDialog: true})
    }
  }

  handleInitialClose = () => {
    localStorage.setItem("first-time", "false");
    this.setState({openInitialDialog: false})
  };

  getElements = () => {

    let elements = !store.getState().section.elementos ? elementosDefault :
        store.getState().section.elementos
            .filter(o => o.field_type !== 'line' && o.field_type !== 'header');
            // .filter(o => o.field_type !== 'line' && o.field_type !== 'header' && o.field_type !== 'calculation');

    elements = elements.map(o => {

      if(o.data_type !== 'DATE') return o;

      o.value = o.value !== '' ? o.value : new Date().toISOString().split('T')[0];

      return o;
    });


    return elements;
  };

  compareConditionalFirstElement = (firstElement) => {
    const elementSelect = this.compareConditional(firstElement);
    console.log('entra en la validación', elementSelect)

    if(!elementSelect){
      this.nextElement();
    }
  }
  getValueCheckBox = (element, event) => {
    let valueArray = element.value === ''? [] : element.value;
    if(event.target.checked) {
      valueArray.push(event.target.value);
    }
    else
      valueArray = valueArray.filter(o => o !== event.target.value);

    return valueArray;
  };

  handleChange = () => async event => {
    console.log("cambiando")
    let elemento = this.state.elementSelected;

    if (event.target.type === 'checkbox')
      elemento.value = this.getValueCheckBox(elemento, event);
    else if (event.target.type === 'file') {
      const filename = await this.handleUpload(event.target.files[0]);
      elemento.value = filename;
      // elemento.value = id_essay+"/"+id_patient+"/"+filename;
    }
    else
      elemento.value = event.target.value;

    console.log('Elemento al moificarlo', elemento)
    this.setState({elementSelected: elemento});
  };

  handleUpload = (file) => {

    let formdata = new FormData();

    formdata.append('patient', id_patient);
    formdata.append('project', id_essay);
    formdata.append('file', file);
    // formdata.append('url_imagen', file.name);
    // formdata.append('name', file.name);


    console.log('file:', file);
    console.log('formdata', formdata);


    return axios({
      method: 'post',
      url: "https://www.sspa.juntadeandalucia.es/servicioandaluzdesalud/hhuuvr/innovacion/app/upload",
      // url: "http://10.232.5.179/app/upload",
      // url: config.apiUrl,
      data: formdata,
      headers: {'Content-Type': 'multipart/form-data' }
    })
        .then(function (response) {
          //handle success
          console.log(response);

          return file.name;
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });

  }

  isValidate = () => {
    let element = this.state.elementSelected;
    let validated = true;
    //Validacion de límites con números

    if(element.validation =! "" && typeof element.validation !== "undefined"){
      validated = !(parseInt(element.value) > parseInt(element.validation_max) || parseInt(element.value) < parseInt(element.validation_min))
      if (!validated)
        this.setState({error: element.validation_error_msg})
    }

    return validated;
  };

  handleClick = () => {
    if(this.isValidate()){
      this.clearMessage();
      this.nextElement();
    }
  }

  //limpia error
  clearMessage = () => {
    this.setState({error: ""})
  }

  handleClickBack = () => this.beforeElement();

  nextElement = () => {
    const { elementos, index } = this.state;
    if(elementos.length-1 === index) {
      this.saveSection(elementos);
    } else {
      const elementSelect = this.compareConditional(elementos[index + 1]);
      if(!elementSelect){
        this.setState({ index: index + 1 }, () => {
          setTimeout(this.nextElement, 0); // Delega la llamada para después del renderizado
        });
      } else {
        this.setState({elementSelected: elementSelect, index: index + 1});
      }
    }
  };

  beforeElement = () => {
    const { elementos, index } = this.state;
    if(0 === index) {
      // this.setState({redirect:true}) No hace nada
    } else {
      const elementSelect = this.compareConditional(elementos[index - 1]);
      if(!elementSelect){
        this.setState({index: index - 1}, () =>this.beforeElement());
      } else {
        this.setState({elementSelected: elementSelect, index: index - 1});
      }
    }
  }

  compareConditional = (elementSelected) => {
    if (elementSelected.conditional_display_item === ""){
      return elementSelected;
    }
    else {
      //Necesitamos buscar en el formulario
      const form = this.props.sections;

      const section = form.filter(sec =>  0 < sec.elementos.filter(o => o.item_name === elementSelected.conditional_display_item).length)

      console.log('esta es la sección encontrada:', section);

      const elements = section[0].elementos.filter(o => o.item_name === elementSelected.conditional_display_item);
      const element = elements.length === 0 ? null : elements[0];
      const req =  !element || !element.value.includes(elementSelected.conditional_display_value) ? null : elementSelected;
      //Si está oculto borro su valor para no crear incongruencias
      if(req == null)
        elementSelected.value = "";
      return req;
    }

  };
  getJsonSearch(location = window.location){
    return qs.parse(location.search.substring(1));
  }

  saveSection(elements){
    console.log('Entra en la funcion', this.props.sections.length)
    const completed = this.props.sections.length == 1 ? true:false

    this.setState({loading: true})

    return axios.post(config.apiUrl, bodyGet("OPERACION_7_MOV", {form: this.props.formId, elementos: elements, completed: completed, opentoken: this.getJsonSearch().opentoken, userSign: utils.getUserSign()}))
        .then(res => {
          console.log('Guardado',res);
          this.setState({loading: false})
          this.setState({canLeave:true})
          this.setState({recommendations: res.data.recomendaciones.length})
          //Redirige cuando me devuelve una redirección
          if(res.data.redirect){
            this.props.history.push(this.getRoute(res.data.redirect));
          }
          this.setState({redirect:true})
          return res;
        })
        .catch(err => {
          console.log('Error:', err.response);

          if(err.response === undefined)
            this.setState({error: 'No ha sido posible enviar el formulario. Compruebe si tiene conexión a internet y vuelva a intentarlo.'})
          else {
            if (err.response.status === 406)
              this.setState({error: err.response.data.descripcion})
          }
          return null;
        })
  }
  getRoute = (path, query='') => `${path}?app=${this.props.app}${!query ? '' : `&${query}`}`;
  renderRedirect = () => {
    // Existe la opción de mandar a la pantalla recomendación o al menú (depende si existe o no recomendación)
    if (this.state.redirect) {
      if(this.props.breadCrumbs.length == 1 || this.props.breadCrumbs.length == 1) {
        this.props.openModal(true);
        if(window.location.pathname === "/open-element")
          this.props.history.push(this.getRoute('/open-end'))
        else{
          if(this.state.recommendations != 0)
            this.props.history.push(this.getRoute('/recommendation'))
          else
             this.props.history.push(this.getRoute('/'))
        }
      }
      else
        this.props.history.goBack();
      // return <Redirect to={this.getRoute('/menu')} />
      // this.props.history.push(this.getRoute('/document/p'+localStorage.getItem("id_patient")))
    }
  }
  isLastElement = () => this.state.elementos.filter((el) => el.calculation == "").length-1 === this.state.index;
  isFirstElement = () => this.state.index === 0;
  completed = () => ((this.state.index+1) * 100 / this.state.elementos.filter((el) => el.calculation == "").length)

  render() {
    const element = this.state.elementSelected;
    // if(element.field_type === 'line' || element.field_type === 'header' || element.field_type === 'calculation') this.nextElement();

    return (
        <>

          <Prompt
              message="¿Está seguro de salir sin guardar? Los datos se perderán."
              when={!this.state.canLeave}
          />
        {this.renderRedirect()}
          <BasicForm {...element}
             app={this.props.app}
             onChange={this.handleChange()}
             onClick={this.handleClick}
             onBack={this.handleClickBack}
             isLastElement={this.isLastElement()}
             isFirstElement={this.isFirstElement()}
             history={this.props.history}
             completed={this.completed()}
          />
          {/*<Grid>*/}
          {/*  <p style={{color: 'red'}}>{this.state.error}</p>*/}
          {/*</Grid>*/}
          <GenericSnackbar message={this.state.error} severity={'error'} clearMessage={this.clearMessage}/>
          {/*Dialog que muestra la pantalla de inicio con varios formularios*/}
          <AlertDialogSlide
            title={'Formulario Virtual'}
            contentText={"Bienvenido a la aplicación de cuestionarios de salud. Podrá completar el formulario hospitalario de forma rápida y sencilla"}
            open={this.state.openInitialDialog && localStorage.getItem("first-time") != "false"}
            handleClose={this.handleInitialClose}
            image={"https://img.freepik.com/vector-gratis/grupo-personal-medico-que-lleva-iconos-relacionados-salud_53876-43071.jpg"}
          />
          {this.state.loading ?
              <div style={{   position: 'fixed', // Posicionamiento fijo en la pantalla
                top: '50%',       // Centrado verticalmente
                left: '50%',      // Centrado horizontalmente
                transform: 'translate(-50%, -50%)', // Ajuste fino para centrar exactamente
                zIndex: 1000}}>
                <CircularProgress style={{width: 200, height: 200}}/>
              </div> : null}

        </>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    formId: state.formId,
    breadCrumbs: state.breadCrumbs,
    sections: state.form
  };
}
function mapDispatchToProps(dispatch) {
  return {
    openModal: open => dispatch(openModal(open))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Elements);
