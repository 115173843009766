// src/js/actions/index.js
import {
    ADD_ARTICLE,
    LOAD_FORM,
    LOAD_SECTION,
    DELETE_BREAD_CRUMBS,
    ADD_BREAD_CRUMBS,
    CLEAR_BREAD_CRUMBS,
    LOAD_FORM_ID,
    LOAD_MAIN_MENU,
    OPEN_MODAL,
    CHANGE_NAME,
    SET_AGREEMENT,
} from "../constants/action-types";
export function addArticle(payload) {
    return { type: ADD_ARTICLE, payload };
}

export function loadSection(payload) {
    return { type: LOAD_SECTION, payload };
}

export function openModal(payload) {
    return { type: OPEN_MODAL, payload };
}
export function changeName(payload) {
    return { type: CHANGE_NAME, payload };
}
export function loadForm(payload) {
    return { type: LOAD_FORM, payload };
}
export function loadFormId(payload) {
    return { type: LOAD_FORM_ID, payload };
}
export function loadMainMenu(payload) {
    return { type: LOAD_MAIN_MENU, payload };
}
export function addBreadCrumbs(payload) {
    return { type: ADD_BREAD_CRUMBS, payload };
}

export function deleteBreadCrumbs() {
    return { type: DELETE_BREAD_CRUMBS };
}

export function clearBreadCrumbs() {
    return { type: CLEAR_BREAD_CRUMBS };
}

export function setAgreement(payload) {
    return {type: SET_AGREEMENT, payload};
}