import React from 'react';
import TextField from '@material-ui/core/TextField';
import {withStyles} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import './style.css';

const styles = theme => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
});

export default withStyles(styles)(({label, ...props}) => {
    return (
      <FormControl component="fieldset" className={props.classes.formControl}>
          <p dangerouslySetInnerHTML={{__html: props.value}}></p>
      </FormControl>
    )
});
